<template>
    <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
        <Dialog class="relative z-10" @close="$emit('close')">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95">
                    <DialogPanel
                        class="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">

                        <SimpleNotification :show="showNotification" :type="notificationType" style="z-index:3000"
                            :message="notificationTitle" :detail="notificationMessage"
                            @close="showNotification = false" />

                        <Combobox v-slot="{ activeOption }">
                            <div class="relative">
                                <MagnifyingGlassIcon
                                    class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true" />
                                <ComboboxInput
                                    class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                    placeholder="Rechercher..."
                                    @change="query = $event.target.value; searchStructures($event.target.value)"
                                    @blur="query = ''" />
                            </div>

                            <ComboboxOptions v-if="query === '' || filteredPeople.length > 0"
                                class="flex transform-gpu divide-x divide-gray-100" as="div" static hold>
                                <div
                                    :class="['max-h-[500px] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4', activeOption && 'sm:h-[500px]']">
                                    <h2 v-if="query === ''" class="mb-4 mt-2 text-xs font-semibold text-gray-500">
                                        Utilisateurs</h2>
                                    <div hold class="-mx-2 text-sm text-gray-700" v-if="recent || filteredPeople">
                                        <ComboboxOption v-for="person in query === '' ? recent : filteredPeople"
                                            :key="person.id" :value="person" as="template" v-slot="{ active }">
                                            <div
                                                :class="['group flex cursor-default select-none items-center rounded-md p-2', active && 'bg-gray-100 text-gray-900']">
                                                <img :src="person.profile_picture_url" alt=""
                                                    v-if="person.profile_picture_url"
                                                    class="h-6 w-6 flex-none rounded-full mr-1" />
                                                <UserCircleIcon
                                                    class="h-8 w-8 text-gray-300 -ml-1 flex-none rounded-full"
                                                    aria-hidden="true" v-else />
                                                <span class="ml-3 flex-auto truncate">{{ person.user.name }}</span>
                                                <ChevronRightIcon v-if="active"
                                                    class="ml-3 h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                            </div>
                                        </ComboboxOption>
                                    </div>
                                </div>

                                <div v-if="activeOption"
                                    class="hidden h-[500px] w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                                    <div class="flex-none p-6 text-center">
                                        <img :src="activeOption.profile_picture_url" alt=""
                                            v-if="activeOption.profile_picture_url"
                                            class="mx-auto h-16 w-16 rounded-full object-cover" />
                                        <UserCircleIcon class="mx-auto h-16 w-16 rounded-full text-gray-300"
                                            aria-hidden="true" v-else />
                                        <h3 class="mt-3 font-semibold text-gray-900">
                                            {{ activeOption.user.name }}
                                        </h3>
                                        <span :class="'bg-role' + activeOption.role.id"
                                            class="mr-2 inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">

                                            {{ activeOption.role.name }}
                                        </span>
                                    </div>
                                    <div class="flex flex-auto flex-col justify-between p-6">
                                        <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                            <dt class="col-end-1 font-semibold text-gray-900">Phone</dt>
                                            <dd>{{ activeOption.phone_number }}</dd>
                                            <dt class="col-end-1 font-semibold text-gray-900">Address</dt>
                                            <dd class="truncate">

                                                {{ activeOption.address }}

                                            </dd>
                                            <dt class="col-end-1 font-semibold text-gray-900">Code postal</dt>
                                            <dd class="truncate">

                                                {{ activeOption.zip_code }}

                                            </dd>
                                            <dt class="col-end-1 font-semibold text-gray-900">Ville</dt>
                                            <dd class="truncate">

                                                {{ activeOption.city }}

                                            </dd>

                                            <dt class="col-end-1 font-semibold text-gray-900">Structures</dt>

                                        </dl>
                                        <div class="flex flex-wrap">
                                            <div class="mr-1 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                v-for="(attachedStructure) in activeOption.structures"
                                                :key="attachedStructure.id">
                                                <svg class="h-1.5 w-1.5 fill-indigo-500" viewBox="0 0 6 6"
                                                    aria-hidden="true">
                                                    <circle cx="3" cy="3" r="3" />
                                                </svg>{{
                                                attachedStructure?.name
                                                }}
                                            </div>
                                        </div>
                                        <button type="button" @click.prevent="onSelect(activeOption)"
                                            class="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Associer</button>
                                    </div>
                                </div>
                            </ComboboxOptions>

                            <div v-if="query !== '' && filteredPeople.length === 0"
                                class="px-6 py-14 text-center text-sm sm:px-14">
                                <UsersIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                                <p class="mt-4 font-semibold text-gray-900">Aucun profil n'a été trouvé</p>
                                <p class="mt-2 text-gray-500">Nous ne trouvons pas de réponse, veuillez réessayer.</p>
                            </div>
                        </Combobox>
                    </DialogPanel>
                </TransitionChild>

            </div>
        </Dialog>
    </TransitionRoot>

</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { ChevronRightIcon, UsersIcon } from '@heroicons/vue/24/outline'
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import { UserCircleIcon } from '@heroicons/vue/24/solid';
import client from '@/api/client';
import { debounce } from 'lodash';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const recent = ref([]);
const displayEditDialog = ref(false);
const displayConfirmDeleteDialog = ref(false);
const open = ref(true);
const query = ref('');
const filteredPeople = ref([]);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');

const props = defineProps({
    idTrainingAction: [Number, String],
});

const emit = defineEmits(['confirm', 'close']);

const onSelect = async (person) => {
    try {
        const response = await client.post(`api/training-actions/${props.idTrainingAction}/add-profile`, {
            profile_id: person.id,
            role_id: person.role_id,
        });
        console.log('response', response.data);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, le profil a bien été ajouté';
        notificationMessage.value = '';
        emit('confirm');
    } catch (error) {
        showNotification.value = true;
        console.error('Failed to attach profile', error);
        notificationType.value = 'error';
        notificationTitle.value = 'Erreur lors de l\'ajout. ';
        notificationTitle.value += error.response.data.error;
    }
}

const searchStructures = debounce(async (keyword) => {
    try {
        const response = await client.get(`api/profiles-search?name=${keyword}`);
        filteredPeople.value = response.data;
    } catch (error) {
        console.error('Failed to fetch structures details:', error);
    }
}, 600);

onMounted(async () => {
    open.value = true;
    try {
        const response = await client.get(`api/profiles-search?name= `);
        filteredPeople.value = response.data;
    } catch (error) {
        console.error('Failed to fetch degrees', error);
    }
});

</script>