<template>
  <div class="bg-white">
    <div>
      <!-- Mobile filter dialog -->
      <TransitionRoot as="template" :show="mobilefoldersOpen">
        <Dialog class="relative z-40 lg:hidden" @close="mobilefoldersOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
            leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div class="fixed inset-0 z-40 flex">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel
                class="relative ml-auto flex h-full w-full max-w-lg flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                <div class="flex items-center justify-between px-4">
                  <router-link to="/documents"><button class="flex items-center pb-4 text-left pt-4">
                      <ChevronLeftIcon class="h-8 w-8 mr-3 text-indigo-700" /> Voir toutes les actions
                    </button></router-link>
                  <button type="button"
                    class="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    @click="mobilefoldersOpen = false">
                    <span class="sr-only">Close menu</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <!-- folders -->
                <form class="mt-4 border-t border-gray-200 px-4">
                  <div class="flex items-center border-b border-gray-200 py-4">
                    <router-link :to="'/documents/action/' + actionSelected?.id" class="flex items-center">
                      <FolderIcon class="h-8 w-8 mr-3 text-indigo-700" />
                      <p class="text-indigo-700 font-semibold">{{ actionSelected?.degree?.name }} - {{
                        actionSelected?.year }}
                      </p>
                    </router-link>
                  </div>
                  <div v-for="relevantFolder in relevantFolders.folders" :key="relevantFolder.id">
                    <SubFolderAccordion :key="componentKey" :folder="relevantFolder" :folderSelected="folderSelected" />
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <div class="mx-auto max-w-7xl px-2 sm:pr-6 lg:pr-8">
        <button type="button" class="py-4 text-gray-600 hover:text-gray-500 lg:ml-6 lg:hidden flex items-center"
          @click="mobilefoldersOpen = true">
          <ListBulletIcon class="h-5 w-5 mr-2" aria-hidden="true" />Explorateur de fichiers
        </button>

        <section aria-labelledby="folders-heading" class="lg:pb-24">
          <h2 id="folders-heading" class="sr-only">Dossiers</h2>

          <!-- folders -->
          <form class="hidden lg:block">
            <router-link to="/documents"><button class="flex items-center pb-4 text-left">
                <ChevronLeftIcon class="h-8 w-8 mr-3 text-indigo-700" /> Voir tous les documents
              </button></router-link>
            <div class="flex items-center border-b border-gray-200 py-6" v-if="action">
              <router-link :to="'/documents/action/' + actionSelected?.id" class="flex items-center">
                <FolderIcon class="h-8 w-8 mr-3 text-indigo-700" />
                <p class="text-indigo-700 font-semibold">{{ actionSelected?.degree?.name }} - {{ actionSelected?.year }}
                </p>
              </router-link>
            </div>
            <div v-for="relevantFolder in relevantFolders.folders" :key="relevantFolder.id">
              <SubFolderAccordion :key="componentKey" :folder="relevantFolder" />
            </div>
          </form>

        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { XMarkIcon, FolderIcon, DocumentIcon, ListBulletIcon } from '@heroicons/vue/24/outline'
import { ChevronLeftIcon, MinusIcon, PlusIcon } from '@heroicons/vue/20/solid'
import client from '@/api/client';
import { useRoute, useRouter } from 'vue-router';
import SubFolderAccordion from '@/components/files/SubFolderAccordion.vue';

const props = defineProps({
  document: [Number, String],
  action: [Number, String],
  folder: [Number, String],
});

const route = useRoute();
const emit = defineEmits(['update', 'changePage']);
const folderSelected = ref(null);
const actionSelected = ref(null);
const componentKey = ref(0);
const relevantFolders = ref([]);
const mobilefoldersOpen = ref(false);

const fetchFolders = async (action_id) => {
  try {
    const response = await client.get(`/api/training-actions/${action_id}/folders`);
    relevantFolders.value = response.data;
    if (props.folder) {
      folderSelected.value = relevantFolders.value.folders.find(folder => Number(folder.id) === Number(props.folder));
    };
    componentKey.value += 1;
  } catch (error) {
    console.log(error);
  }
}

onMounted(async () => {
  if (route.params.idAction) {
    fetchFolders(route.params.idAction);
  } else if (props.action) {
    fetchFolders(props.action);
  }
  if (props.action) {
    try {
      const response = await client.get(`/api/training-actions/${props.action}`);
      actionSelected.value = response.data;
    } catch (error) {
      console.error('Failed to fetch training actions by id', error);
    }
  }
});

</script>
