<template>
  <div class="flex items-end">
    <Listbox as="div" v-model="selected" class="w-full pr-3">
      <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Je choisis la promotion que je vais
        intégrer
      </ListboxLabel>
      <div class="relative mt-2">
        <ListboxButton
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <span class="block truncate" v-if="selected">{{ selected?.degree.name }}</span>
          <span v-else>Je choisis une promo</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
          leave-to-class="opacity-0">
          <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="relevantTrainingAction in relevantTrainingActions"
              :key="relevantTrainingAction.id" :value="relevantTrainingAction" v-slot="{ active, selected }">
              <li
                :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                  relevantTrainingAction.degree.name }} - {{
                    relevantTrainingAction.year }}
                </span>
                <span v-if="selected"
                  :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <div>
      <button class="btn-primary" @click.prevent="addTrainingAction">Sélectionner</button>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import client from '@/api/client';

const emit = defineEmits(['confirm']);

const relevantTrainingActions = ref([]);
const selected = ref();
const store = inject('store');

const currentProfile = store.chosenProfile;

const fetchTrainingActions = async () => {
  const response = await client.get(`/api/next-training-actions`);
  relevantTrainingActions.value = response.data;
};

const addTrainingAction = async () => {
  const response = await client.post(`api/training-actions/${selected.value.id}/add-profile`, {
    profile_id: currentProfile.id,
    role_id: currentProfile.role_id,
  });
  store.fetchUserProfiles();
  store.refreshUserProfiles();
  emit('confirm');
};

onMounted(() => {
  fetchTrainingActions();
});
</script>