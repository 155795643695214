<template>

  <Disclosure as="div" class="border-b border-gray-200 py-4" v-slot="{ open }">
    <h3 class="-my-3 flow-root">
      <div class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">

        <button class="flex items-center" @click="$emit('select', relevantFolder)">
          <FolderIcon class="h-8 w-8 mr-3 min-w-8"
            :class="folderSelected?.id === relevantFolder.id ? 'text-indigo-700' : ''" />
          <span class="font-medium text-gray-900"
            :class="folderSelected?.id === relevantFolder.id ? 'text-indigo-700' : ''">{{ relevantFolder.name
            }}</span>
        </button>

        <DisclosureButton v-if="relevantFolder.children?.length">
          <span class="ml-6 flex items-center">
            <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true" />
            <MinusIcon v-else class="h-5 w-5" aria-hidden="true" />
          </span>
        </DisclosureButton>
      </div>
    </h3>
    <DisclosurePanel class="pt-6">

      <div v-for="subfolder in relevantFolder.children" :key="subfolder.id" class="ml-2">
        <SubFolderAccordionMove :folder="subfolder" :active="open" @select="selectFolder" :selected="selected"
          :folderSelected="folderSelected" />
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { XMarkIcon, FolderIcon, DocumentIcon, ListBulletIcon } from '@heroicons/vue/24/outline'
import { ChevronLeftIcon, MinusIcon, PlusIcon } from '@heroicons/vue/20/solid'
import SubFolderAccordionMove from '@/components/files/SubFolderAccordionMove.vue';

const props = defineProps({
  folder: [Array, Object],
  folderSelected: [Array, Object],
  active: Boolean,
  selected: Boolean,
});
const selectedFolder = ref([]);

const selectFolder = (folder) => {
  emit('select', folder);
}

const emit = defineEmits(['select']);
const relevantFolder = ref([]);

onMounted(() => {
  relevantFolder.value = props.folder;
  selectedFolder.value = props.folderSelected;
});

</script>