<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="closePanel" style="z-index:2001; position:absolute" />
  <TransitionRoot as="template" :show="open">
    <Dialog class="relative z-10" @close="open = false">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" appear enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-base font-semibold leading-6 text-gray-900">Renseigner les dates de
                        l'étape</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button"
                          class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          @click.prevent="closePanel">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <AlertMessage type="info"
                      title="Les dates ne sont pas définitives et pourront être modifiées ultérieurement" />
                    <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-5">
                      <div class="col-span-full">
                        <label for="start-on" class="block text-sm font-medium leading-6 text-gray-900">Début
                        </label>
                        <div class="mt-2">
                          <input type="datetime-local" name="start-on" id="start-on" v-model="formStep.start_date"
                            autocomplete="start-on"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                      </div>

                      <div class="col-span-full">
                        <label for="end-on" class="block text-sm font-medium leading-6 text-gray-900">Fin</label>
                        <div class="mt-2">
                          <input type="datetime-local" name="end-on" id="end-on" v-model="formStep.end_date"
                            autocomplete="end-on"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button"
                      class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                      @click.prevent="closePanel">Annuler</button>
                    <button type="submit" @click.prevent="changeDate"
                      class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Enregistrer</button>
                  </div>

                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, watch } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useRoute } from 'vue-router';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import AlertMessage from '@/components/alert/AlertMessage.vue';

const route = useRoute();
const emit = defineEmits(['update:modelValue', 'confirm', 'close']);

const closePanel = () => {
  open.value = false;
}
const formStep = ref({
  start_date: new Date(),
  end_date: new Date(),
});

const props = defineProps({
  show: Boolean,
  step: [Number, String],
  start: [String, Number],
  end: [String, Number],

});
const open = ref(false);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');

const changeDate = async () => {
  try {
    const response = await client.put(`/api/training-action-step/${props.step}/update-dates`, {
      start_datetime: new Date(formStep.value.start_date),
      end_datetime: new Date(formStep.value.end_date),
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = response.data.message;
    notificationMessage.value = '';
    closePanel();
    emit('confirm');
  } catch (error) {
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur dans l\'enregistrement des dates';
    notificationMessage.value = '';
    console.log(error);
    console.log(error.response);
    console.log(error.response.data.message);
    if (error.response && error.response.data) {
      notificationMessage.value += error.response.data.message;
    }
  }
}

onMounted(async () => {
  open.value = false;
  open.value = true;
  console.log(props.start);
  if (props.step) {
    if (props.start) {
      formStep.value.start_date = props.start;
      formStep.value.start_date = new Date(new Date(formStep.value.start_date).getTime() + 2 * 60 * 60 * 1000).toISOString().slice(0, 16);
    }
    if (props.end) {
      formStep.value.end_date = props.end;
      formStep.value.end_date = new Date(new Date(formStep.value.end_date).getTime() + 2 * 60 * 60 * 1000).toISOString().slice(0, 16);
    }

  }
});

</script>
