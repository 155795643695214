<template>
  <DocumentLayout>
    <template v-slot:title>{{ documentSelected?.folder?.name }}</template>
    <template v-slot:nav>
      <NavFiles :action="documentSelected?.folder?.training_action_id" :folder="documentSelected?.folder_id"
        :key="componentKey" v-if="documentSelected" />
    </template>
    <template v-slot:main>
      <SmallLoader v-if="isLoading" :text="'Chargement en cours'" class="mb-10" />
      <AlertMessage v-if="notAuthorized" :type="'error'" :title="errorMessage" class="w-full"
        @close="notAuthorized = false" />
      <FileManager :document="documentSelected" :key="componentKey" v-if="documentSelected" @update="fetchDocument" />
    </template>
  </DocumentLayout>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import DocumentLayout from '@/layouts/DocumentLayout.vue';
import FileManager from '@/components/files/FileManager.vue';
import client from '@/api/client';
import { useRoute, useRouter } from 'vue-router';
import NavFiles from '@/components/files/NavFiles.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';
import AlertMessage from '@/components/alert/AlertMessage.vue';

const route = useRoute();
const documentSelected = ref(null);
const componentKey = ref(0);
const document = ref([]);
const isLoading = ref(true);
const notAuthorized = ref(false);
const errorMessage = ref('');

const fetchDocument = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`/api/documents/${route.params.idDocument}`);
    documentSelected.value = response.data;
    componentKey.value += 1;
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    notAuthorized.value = true;
    if (error.response?.data?.error) {
      errorMessage.value = error.response.data.error;
    } else {
      errorMessage.value = 'Une erreur est survenue';
    }
  }
}
onMounted(() => {
  fetchDocument();
});
</script>

<style>
p {
  margin-bottom: 1.5rem;
}
</style>