<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <ConfirmationDialog @confirm="removeFolder(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
    title="Supprimer ce dossier" message="Voulez-vous vraiement supprimer ce dossier ?" primaryActionLabel="Supprimer"
    secondaryActionLabel="Annuler" @close="displayConfirmDeleteDialog = false" />
  <AddFolder v-if="displayEditFolder" @close="displayEditFolder = false" @confirm="$emit('update')" :type="typeSelected"
    :folder="folderSelected" :key="componentKey" :no_action="true" />
  <ModalMoveFoldersAndDocuments v-if="displayModalMoveItems" @close="displayModalMoveItems = false"
    @confirm="$emit('update'); typeSelected = []" :folder="folderSelected" :type="'folder'" :folders="relevantFolders"
    :key="componentKey" />
  <div class="bg-white" style="min-height: calc( 100vh - 400px );">
    <div class="w-full">
      <div class="w-full grid 2xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6">
        <div class="card shadow" v-for="folder in relevantFolders.training_actions" :key="folder.id">
          <div class="p-5">
            <div class="space-y-4 text-gray-600 dark:text-gray-300">
              <div class="flex items-start relative gap-5">

                <router-link :to="'/documents/action/' + folder.id"><button class="flex items-center gap-3 text-left">
                    <div class="h-14 w-14">
                      <span class="flex h-full w-full items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                          stroke="currentColor" class="size-20 stroke-indigo-900">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>

                      </span>
                    </div>
                    <div class="space-y-1">
                      <p class="font-semibold text-base">{{ folder.degree.name }} - {{ folder.year }}</p>
                    </div>
                  </button></router-link>

              </div>

            </div>
          </div> <!-- end card body -->
        </div>
        <div class="card shadow" v-for="folder in relevantFolders.folders" :key="folder.id">
          <div class="p-5">
            <div class="space-y-4 text-gray-600 dark:text-gray-300">
              <div class="flex items-start relative gap-5">

                <router-link :to="'/documents/dossier/' + folder.id"><button class="flex items-center gap-3 text-left">
                    <div class="h-14 w-14">
                      <span class="flex h-full w-full items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                          stroke="currentColor" class="size-20 stroke-indigo-500">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>

                      </span>
                    </div>
                    <div class="space-y-1">
                      <p class="font-semibold text-base">{{ folder.name }}</p>
                    </div>
                  </button></router-link>
                <div class="ml-auto flex flex-none items-center gap-x-4"
                  v-if="currentProfile?.role.name === 'Administrateur·trice'">
                  <Menu as="div" class="relative flex-none">
                    <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                      <span class="sr-only">Open options</span>
                      <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                    </MenuButton>
                    <transition enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <MenuItems
                        class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <MenuItem v-slot="{ active }" v-if="currentProfile?.role.name === 'Administrateur·trice'">
                        <a href="#" @click="editFolder('edition', folder)"
                          :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Editer<span
                            class="sr-only">, {{ folder.name }}</span></a>
                        </MenuItem>
                        <MenuItem v-slot="{ active }" v-if="currentProfile?.role.name === 'Administrateur·trice'">
                        <a href="#" @click="moveFolder(folder, 'folder')"
                          :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Déplacer<span
                            class="sr-only">, {{ folder.name }}</span></a>
                        </MenuItem>

                        <MenuItem v-slot="{ active }" v-if="currentProfile?.role.name === 'Administrateur·trice'">
                        <a href="#" @click.prevent="displayConfirmDeleteDialog = true; idTypeToEdit = folder.id"
                          :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Supprimer<span
                            class="sr-only">, {{ folder.name }}</span></a>
                        </MenuItem>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
              </div>

            </div>
          </div> <!-- end card body -->
        </div>
        <button v-if="currentProfile?.role.name === 'Administrateur·trice'" type="button"
          @click.prevent="editFolder('creation')" class="mb-4 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center
              hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor"
            class="mx-auto h-12 w-12  text-slate-400">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
          </svg>

          <span class="mt-2 block text-sm font-medium  text-slate-400">Ajouter un dossier</span>
        </button>
      </div>
    </div>

  </div>

</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AddFolder from '@/components/files/AddFolder.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
import client from '@/api/client';
import ModalMoveFoldersAndDocuments from '@/components/files/ModalMoveFoldersAndDocuments.vue';

const router = useRouter();
const displayEditFolder = ref(false);
let typeSelected = ref(null);
let componentKey = ref(0);
let folderSelected = ref(null);
const idTypeToEdit = ref(null);

const store = inject('store');
const currentProfile = ref(store.chosenProfile);

const displayConfirmDeleteDialog = ref(false);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const displayModalMoveItems = ref(false);
const relevantFolders = ref([]);

const props = defineProps({
  profile: [Object, Array],
  folders: [Object, Array],
});
const emit = defineEmits(['update', 'changePage']);

const editFolder = (type, folder) => {
  typeSelected.value = type;
  folderSelected.value = folder;
  componentKey.value += 1;
  displayEditFolder.value = true;
}

const removeFolder = async (id) => {
  displayConfirmDeleteDialog.value = false;
  try {
    const response = await client.delete(`/api/folders/${id}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été supprimé';
    notificationMessage.value = '';
    emit('update');
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur lors de la suppression';
    notificationMessage.value = ` ${error.response.data.error}`;
  }
}


const moveFolder = (item, type) => {
  if (type === 'folder') {
    folderSelected.value = item;
  }
  typeSelected.value = type;
  displayModalMoveItems.value = true;
  componentKey.value += 1;
}


onMounted(() => {
  relevantFolders.value = props.folders;
});
</script>
