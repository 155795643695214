<template>
    <div class="">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h2 class="text-base font-semibold leading-6 text-gray-900">Étapes Fest</h2>
                <p class="mt-2 text-sm text-gray-700">Par action de formation</p>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button type="button" v-if="!cantEditItems" @click="displaySelectProfile = true; componentKey++"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                    les étapes pour un apprenant</button>
            </div>
            <div class="mt-4 sm:ml-3 sm:mt-0 sm:flex-none">
                <button type="button" v-if="!cantEditItems" @click="postStep(trainingActionId)"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Créer
                    pour
                    toute la formation</button>
            </div>
        </div>
        <SelectTrainingAction v-model="trainingActionId" class="mt-10" @confirm="selectTrainingAction" />
        <div class="grid grid-cols-1 divide-y gap-4 mt-10">
            <div v-for="student in studentSteps" :key="student.id" class="pt-5">
                <div class="flex items-center">
                    <p class="font-medium text-gray-900">{{ student.learner.user.name }} </p>
                </div>

                <TimelineStepsByStudent :steps="student.steps.data" v-if="student.steps?.data.length > 0"
                    :key="componentKey" />
                <div v-else class="bg-gray-100 rounded-md p-4 mt-4 text-gray-700 text-sm">
                    Pas d'étape enregistrée
                </div>
            </div>
            <div v-if="studentSteps.length == 0">Pas de résultats</div>
        </div>
        <AlertMessage type="info" :title="'Veuillez sélectionner une action de formation'" v-if="!trainingActionId" />
    </div>
    <AdminDialog @confirm="editItem" :typeEdition="typeEdition" :title="confirmTexts[1].title" :size="'fullscreen'"
        @close="displayEditDialog = false" v-model="typeModelForm" :objectToModify="dataToModify"
        v-if="displayEditDialog" />
    <ConfirmationDialog @confirm="deleteItem(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
        :title="confirmTexts[0].title" :message="confirmTexts[0].message"
        :primaryActionLabel="confirmTexts[0].primaryActionLabel"
        :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
    <ModalSelectFolderForStep v-if="displayModalSelectFolder" @close="displayModalSelectFolder = false"
        @confirm="selectFolder" :type="'folder'" :training_action="trainingAction" :action="trainingActionId"
        :key="componentKey" />
    <AddStepsForAProfile v-if="displaySelectProfile" @close="displaySelectProfile = false" @confirm="selectProfile"
        :action="trainingActionId" :key="componentKey" />
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed, inject } from 'vue';
import client from '@/api/client';
import Pagination from '@/components/lists/PaginationTables.vue';
import AdminDialog from '@/components/dialogs/AdminDialog.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchEventServices from '@/services/EventServices.js';
import { CalendarDaysIcon, MapPinIcon, ClipboardDocumentCheckIcon, ListBulletIcon } from '@heroicons/vue/24/outline';
import TimelineStepsByStudent from '@/components/steps/TimelineStepsByStudent.vue';
import SelectTrainingAction from '@/components/steps/SelectTrainingAction.vue';
import AlertMessage from '@/components/alert/AlertMessage.vue';
import ModalSelectFolderForStep from '@/components/steps/ModalSelectFolderForStep.vue';
import AddStepsForAProfile from '@/components/steps/AddStepsForAProfile.vue';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const cantEditItems = computed(() => (currentProfile.value.role.name === 'Formateur·trice référent·e'));

const relevantEvents = ref([]);

const displayEditDialog = ref(false);
const displayConfirmDeleteDialog = ref(false);
const displayZones = ref(false);
const typeEdition = ref('creation');
const idTypeToEdit = ref(null);
const dataToModify = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = ref(10);
const studentSteps = ref([]);
const trainingActionId = ref(null);
const trainingAction = ref(null);
const displayModalSelectFolder = ref(false);
const componentKey = ref(0);
const displaySelectProfile = ref(false);

const confirmTexts = [
    {
        title: 'Supprimer cet item ?',
        message: 'Voulez-vous supprimer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
    {
        title: 'Éditer un événement',
        message: 'Voulez-vous éditer cet item',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]

const steps = ref([
    {
        id: 1,
        name: 'Rendez-vous 1',
        step_type: 'rendezvous',
        order: 1,
    },
    {
        id: 2,
        name: 'Rendez-vous 2',
        step_type: 'rendezvous',
        order: 2,
    },
    {
        id: 3,
        name: "Situation de travail 1",
        step_type: "situation",
        order: 3,
    },
    {
        id: 4,
        name: "Situation de travail 2",
        step_type: "situation",
        order: 4,
    },
    {
        id: 5,
        name: "Situation de travail 3",
        step_type: "situation",
        order: 5,
    },
    {
        id: 6,
        name: "Situation de travail 4",
        step_type: "situation",
        order: 6,
    },
    {
        id: 7,
        name: "Bilan",
        step_type: "bilan",
        order: 7
    }
],);

const changePage = (page) => {
    fetchEventServices.fetchEvents(page, pageSize.value).then((data) => {
        relevantEvents.value = data.data;
        currentPage.value = data.current_page;
    });
};

const postStep = async (trainingActionId) => {
    let stepTemplates = [];
    stepTemplates = steps.value;
    try {
        const response = await client.post(`/api/training-action/${trainingActionId}/pre-create-steps`, {
            step_template: stepTemplates,
            include_dates: false,
            include_documents: true
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été créé';
        notificationMessage.value += '';
        getStudentSteps();
    } catch (error) {
        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Creation Error';
        notificationMessage.value = `Failed to create folder`;
    }
}
const postStudentSteps = async (trainingActionId, profileId) => {
    let stepTemplates = [];
    stepTemplates = steps.value;
    try {
        const response = await client.post(`/api/training-action/${trainingActionId}/learner/${profileId}/pre-create-steps`, {
            step_template: stepTemplates,
            include_dates: false,
            include_documents: true,
        });
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = response.data.message;
        getStudentSteps();
    } catch (error) {
        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = error.response.data.message;
    }
}
const getStudentSteps = async () => {
    try {
        const response = await client.get(`/api/training-action/${trainingActionId.value}/learners-with-steps`);
        studentSteps.value = response.data.data;
    } catch (error) {
        showNotification.value = true;
        notificationType.value = 'error';
        notificationTitle.value = 'Erreur dans le chargement des étapes';
    }
}

const selectTrainingAction = (trainingAction) => {
    trainingActionId.value = trainingAction.id;
    trainingAction.value = trainingAction;
    getStudentSteps();
}

const selectFolder = (folder) => {
    console.log(folder);
}

const selectProfile = (trainingAction, profile) => {
    postStudentSteps(trainingAction, profile);
    displaySelectProfile.value = false;
}
onMounted(() => {

});

</script>
