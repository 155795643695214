<template>
  <h3 class="font-medium text-indigo-600 mb-5 border-t border-gray-200 pt-5">Vous avez {{ files.length }} nouveaux
    documents dans votre
    espace
    !</h3>
  <div class="w-full grid 2xl:grid-cols-3 sm:grid-cols-3 grid-cols-1 gap-6">
    <div class="card shadow text-left flex items-center !mb-0 " v-for="file in files" :key="file.id">
      <div class="p-5">
        <div class="space-y-4 text-gray-600 dark:text-gray-300">
          <div class="flex items-start relative gap-5">

            <router-link :to="'/documents/' + file.id"> <button class="flex items-center gap-3 text-left">
                <div class="h-14 w-14">
                  <span class="flex h-full w-full items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                      stroke="currentColor" class="size-20 stroke-teal-500">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg>

                  </span>
                </div>
                <div class="space-y-1">
                  <p class="font-semibold text-base">{{ file.name }}</p>
                  <p class="leading-4 text-sm">Créé le {{ fetchEventServices.formatDate(new
                    Date(file.created_at)) }}</p>
                </div>
              </button></router-link>

          </div>
        </div>
      </div> <!-- end card body -->
    </div>
    <router-link to="/documents/"><button type="button"
        class=" relative block w-full rounded-lg border border-slid border-gray-200 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-gray-50">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor"
          class="mx-auto h-12 w-12 text-gray-400">
          <path stroke-linecap=" round" stroke-linejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
        </svg>

        <span class="mt-2 block text-sm font-medium text-gray-900">Voir tous mes documents</span>
      </button></router-link>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import client from '@/api/client';
import fetchEventServices from '@/services/EventServices.js';

const files = ref([]);
const isLoading = ref(true);
const errorMessage = ref('');

const fetchNewDocuments = async () => {
  try {
    const response = await client.get(`/api/dashboard/last-documents`);
    files.value = response.data;
    componentKey.value += 1;
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    if (error.response?.data?.error) {
      errorMessage.value = error.response.data.error;
    } else {
      errorMessage.value = 'Une erreur est survenue';

    }
  }
};

onMounted(() => {
  fetchNewDocuments();
});
</script>