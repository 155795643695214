<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <AppLayout>
    <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
      <div class="min-w-0 flex-1">
        <div class="hero-structures max-w-[1520px] mx-auto">
          <h1 class="title1 text-2xl lg:text-5xl mb-5 !mt-20 text-white">
            {{ formStep?.name }}
          </h1>
          <h2 class="text-base text-indigo-600 mb-10 text-white">
            <span v-if="formStep?.date_created">Créé le {{ fetchEventServices.formatDateSimple(new
              Date(formStep?.date_created)) }}</span><span v-if="formStep?.date_updated"> - modifié le {{
                fetchEventServices.formatDateSimple(new Date(formStep?.date_updated)) }}</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-1 sm:pt-8 rounded-xl" id="trainingStep">
      <div class=" mx-auto max-w-[1100px] mt-20">
        <router-link class="" :to="'/etape/' + $route.params.idTrainingActionStep">
          <div class="flex items-center mb-10 hover:text-indigo-700">
            <ChevronLeftIcon class="h-8 w-8 flex-shrink-0 text-indigo-600 ml-1 sm:-ml-2" aria-hidden="true" /> Retour
            Fiche
          </div>
        </router-link>

        <div v-for="section in formStep?.sections" :key="section.title" class="mb-10">
          <h3 class="text-xl font-semibold leading-7 text-indigo-700 mb-5">{{ section.title }}</h3>
          <p v-if="section.description">{{ section.description }}</p>

          <div v-for="field in section.fields" :key="field.name">

            <div class="col-span-full"
              v-if="field.type === 'textarea' || field.type === 'text' || field.type === 'date' || field.type === 'number'">
              <label for="about" class="block text-base font-medium leading-6 text-gray-900">{{ field.label }}</label>
              <div class="mt-2 mb-8" v-if="field.type === 'textarea'">
                <textarea :id="field.name" :name="field.name" rows="8" :placeholder="field.placeholder"
                  v-model="field.content"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6" />
              </div>

              <div class="mt-2 mb-8" v-if="field.type === 'text' || field.type === 'date' || field.type === 'number'">
                <input :type="field.type" :id="field.name" :name="field.name" autocomplete="given-name"
                  v-model="field.content"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                  :placeholder="field.placeholder" />
              </div>

            </div>
            <div class="" v-if="field.type === 'radio'">
              <div v-for="choice in field.choices" :key="choice.value">
                <div class="relative flex gap-x-3  mb-2">
                  <div class="flex h-6 items-center">
                    <input :id="choice.name" :name="choice.name" :value="choice.label" type="radio"
                      v-model="field.content"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                  </div>
                  <div class="text-base leading-6">
                    <label for="comments" class="font-medium text-gray-900 text-base">{{ choice.label }}</label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="flex mx-auto mb-10">
          <button class="btn btn-primary ml-auto" @click.prevent="editStep">
            enregistrer
          </button>
        </div>
      </div>

    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import { ChevronLeftIcon, HomeIcon } from '@heroicons/vue/20/solid'
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchEventServices from '@/services/EventServices.js';

const route = useRoute();
const formStep = ref([]);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const store = inject('store');
const trainingStep = ref([]);

const situationForm = ref({
  index: 0,
  name: "Fiche de suivi de positionnement Formation FEST",
  sections: [
    {
      title: "Informations générales",
      fields: [
        {
          label: "Formateur·ice Métier",
          type: "text",
          name: "formateur",
          content: ""
        },
        {
          label: "Apprenant·e",
          type: "text",
          name: "apprenant",
          content: ''
        },
        {
          label: "Date",
          type: "date",
          name: "date",
          content: ''
        },
        {
          label: "Situation choisie",
          type: "text",
          name: "situation",
          content: ''
        },
        {
          label: "Séquence FEST n°",
          type: "number",
          name: "sequence_fest",
          content: ''
        }
      ]
    },
    {
      title: "Positionnement avant la séquence FEST",
      description: "Ceci n’est pas une évaluation de compétences. C’est un positionnement qui va permettre de voir sur quoi organiser les séquences de formation.",
      fields: [
        {
          type: "radio",
          name: "positionnement_amont",
          content: '',
          choices: [
            { label: "Je ne sais pas encore faire", value: "Je ne sais pas encore faire" },
            { label: "Je sais faire mais avec une aide", value: "Je sais faire mais avec une aide" },
            { label: "Je sais faire en autonomie", value: "Je sais faire en autonomie" },
            { label: "Je sais faire face à des imprévus", value: "Je sais faire face à des imprévus" }
          ]
        },
      ]
    },
    {
      title: "Positionnement après la séquence FEST",
      fields: [
        {
          label: "Je note au moins 3 points sur lesquels je suis satisfait·e, ce qui a fonctionné",
          type: "textarea",
          name: "points_satisfaction",
          placeholder: "Ex: Organisation, gestion de l’espace, communication, etc.",
          content: ''
        },
        {
          label: "J’identifie, à ce stade mes besoins de progression : j’ai encore besoin de…",
          type: "textarea",
          name: "besoins_progression",
          placeholder: "Ex: Nouvelle mise en situation, connaissances complémentaires, etc.",
          content: ''
        },
        {
          label: "Nous décidons, formateur métier et apprenant, de…",
          type: "textarea",
          name: "decision_conjointe",
          placeholder: "Ex: Poursuivre la mise en situation, faire une autre mise en situation sur...",
          content: ''
        }
      ]
    }
  ]
});

const editStep = async () => {

  let arrayToPost = {};
  let deliverables = [];
  let dateCreated = null;
  if (formStep.value.date_created) {
    dateCreated = formStep.value.date_created;
  } else {
    dateCreated = new Date();
  }
  deliverables.push({
    index: formStep.value.index,
    name: formStep.value.name,
    date_created: dateCreated,
    date_updated: new Date(),
    sections: formStep.value.sections,
  });
  arrayToPost.deliverables = deliverables;
  try {
    const response = await client.put(`/api/training-action-step/${route.params.idTrainingActionStep}`, arrayToPost);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = response.data.message;
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Creation Error';
    notificationMessage.value = `Erreur dans l'enregistrement`;
  }
}

const getTrainigStep = async () => {
  try {
    const response = await client.get(`/api/training-action-step/${route.params.idTrainingActionStep}`);
    trainingStep.value = response.data;
    if (trainingStep.value.step_type === 'situation') {
      // Check step type;
      // Get the form for situation
      formStep.value = situationForm.value;
    }
    if (response.data.deliverables) {
      formStep.value = response.data.deliverables[0];
    }
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur dans le chargement du formulaire';
  }
}

onMounted(async () => {
  getTrainigStep();
});

</script>
<style scoped></style>
