<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <TransitionRoot as="template" :show="open">
    <Dialog class="relative z-10" @close="$emit('close')">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-3xl">
                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                      <div class="flex items-center justify-between">
                        <DialogTitle class="text-base font-semibold leading-6 text-white"><span
                            v-if="type === 'edition'">Modifier un dossier</span><span v-else>Nouveau dossier</span>
                        </DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button"
                            class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            @click="$emit('close')">
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="space-y-6 pb-5 pt-6">
                          <div>
                            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Nom du
                              dossier</label>
                            <div class="mt-2">
                              <input type="text" name="name" id="name" v-model="formFolder.name"
                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                          </div>
                          <SwitchGroup as="div" class="flex items-center">
                            <Switch v-model="formFolder.private"
                              :class="[formFolder.private ? 'bg-rose-500' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                              <span aria-hidden="true"
                                :class="[formFolder.private ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <SwitchLabel as="span" class="ml-3 text-sm">
                              <span class="font-medium text-gray-900" v-if="!formFolder.private">Public</span>
                              <span class="font-medium text-gray-900" v-else>Privé</span>
                            </SwitchLabel>
                          </SwitchGroup>
                          <div
                            v-if="(currentProfile.role.name !== 'Apprenant·e' && currentProfile.role.name !== 'Formateur·trice métier')">
                            <div v-if="!no_action">
                              <AddUserRelated type="folder" :item="formFolder.id" v-if="formFolder.id" :key="usersKey"
                                :action="formFolder.training_action_id" :makeUnvisible="canMakeUnvisible" />
                              <AddRolesRelated type="folder" :folder="formFolder" :makeUnvisible="canMakeUnvisible"
                                v-if="formFolder.id && currentProfile.role.name !== 'Apprenant·e' && currentProfile.role.name !== 'Formateur·trice métier'"
                                @confirm="usersKey += 1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button"
                      class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      @click="$emit('close')">Annuler</button>
                    <button @click.prevent="editFolder" :disabled="isSubmitted"
                      class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enregistrer</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import AddUserRelated from '@/components/files/AddUserRelated.vue'
import AddRolesRelated from '@/components/files/AddRolesRelated.vue'
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client'
import { useRoute } from 'vue-router';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

const emit = defineEmits(['close', 'confirm']);
const props = defineProps({
  type: {
    type: String,
    default: 'info',
  },
  folder: [Array, Object],
  training_action_id: [Number, String],
  makeUnvisible: {
    Boolean,
    default: true,
  },
  no_action: {
    Boolean,
    default: false,
  }
});
const route = useRoute();
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const usersKey = ref(0);
let canMakeUnvisible = ref(true);
const isSubmitted = ref(false);

const open = ref(true);
const formFolder = ref([]);

const editFolder = () => {
  if (props.type === 'edition') {
    putFolder(formFolder.value);
  } else {
    postFolder();
  }
}

const postFolder = async () => {
  let arrayToPost = {};
  arrayToPost.name = formFolder.value.name;
  arrayToPost.private = formFolder.value.private;
  if (route.params.idFolder) {
    arrayToPost.parent_id = route.params.idFolder;
  }
  if (props.training_action_id) {
    arrayToPost.training_action_id = props.training_action_id;
  }
  if (!isSubmitted.value) {
    isSubmitted.value = true;
    try {
      const response = await client.post(`/api/folders`, arrayToPost);
      showNotification.value = true;
      setTimeout(() => { showNotification.value = false; }, 3000);
      notificationType.value = 'success';
      notificationTitle.value = 'Bravo, l\'item a bien été créé';
      notificationMessage.value += '';
      formFolder.value = response.data;
      emit('confirm');
    } catch (error) {
      showNotification.value = true;
      notificationType.value = 'error';
      notificationTitle.value = 'Creation Error';
      notificationMessage.value = `Failed to create folder`;
    }
  }
}
const putFolder = async () => {
  try {
    const response = await client.put(`/api/folders/${props.folder.id}`, {
      name: formFolder.value.name,
      private: formFolder.value.private,
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationMessage.value += '';
    emit('confirm');
    emit('close');
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Creation Error';
    notificationMessage.value = `Failed to create folder`;
  }
}
onMounted(async () => {
  canMakeUnvisible = props.makeUnvisible;
  if (props.folder) {
    formFolder.value = props.folder;
  }
});

</script>