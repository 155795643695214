<template>

  <Listbox as="div" v-model="selected" class="pt-10 mt-10" style="z-index:100; position:relative">
    <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Choisissez l'action de formation
    </ListboxLabel>
    <div class="relative mt-2">
      <ListboxButton
        class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
        <span class="flex items-center" v-if="selected">
          <span class="ml-3 block truncate">{{ selected?.degree?.name }} - {{ selected?.year }}</span>
        </span><span v-else>Aucune action sélectionnée</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="trainingAction in relevantTrainingActions" :key="trainingAction.id"
            :value="trainingAction" v-slot="{ active, selected }" @click="$emit('confirm', selected)">
            <li
              :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
              <div class="flex items-center">
                <span
                  :class="[new Date(trainingAction.end_date) > new Date() ? 'bg-green-400' : 'bg-gray-200', 'inline-block h-2 w-2 flex-shrink-0 rounded-full']"
                  aria-hidden="true" />
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                  {{ trainingAction.degree.name }} - {{ trainingAction.year }}
                  <span class="sr-only"> is {{ new Date(trainingAction.end_date) > new Date() ? 'online' : 'offline'
                    }}</span>
                </span>
              </div>

              <span v-if="selected"
                :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import fetchServices from '@/services/fetchServices';

const relevantTrainingActions = ref([]);
const currentPage = ref(1);
const pageSize = 100;
const totalPages = ref(0);
const totalItems = ref(0);

const selected = ref();
const emit = defineEmits(['confirm']);

onMounted(() => {
  relevantTrainingActions.value =
    fetchServices.fetchTrainingActions(currentPage.value, pageSize).then((data) => {
      relevantTrainingActions.value = data.data;
      currentPage.value = data.current_page;
      totalPages.value = data.last_page;
      totalItems.value = data.total;
    });
});
</script>