<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <div>
    <h3 class="text-sm font-medium leading-6 text-gray-900 mb-5 mt-10">Modifier les droits pour tous les rôles</h3>
    <div class="mt-2 mb-4" v-if="availableRoles">
      <Listbox as="div" v-model="selectedRole">
        <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Choisir un rôle</ListboxLabel>
        <div class="relative mt-2">
          <ListboxButton
            class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <span class="block truncate" v-if="selectedRole">{{ selectedRole.name }}</span>
            <span v-else>Choisir un rôle</span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ListboxOption as="template" v-for="role in availableRoles" :key="role.id" :value="role"
                v-slot="{ active, selected }">
                <li
                  :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ role.name }}</span>

                  <span v-if="selected"
                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <Listbox as="div" v-model="selectedAccess" class="mt-5">
        <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Sélectionner des droits</ListboxLabel>
        <div class="relative mt-2">
          <ListboxButton
            class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <span class="block truncate" v-if="selectedAccess">{{ selectedAccess.name }}</span>
            <span v-else>Choisir des droits</span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ListboxOption as="template" v-for="right in rights" :key="right.id" :value="right"
                v-slot="{ active, selected }">
                <li
                  :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ right.name
                    }}</span>

                  <span v-if="selected"
                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <button @click.prevent="changeRights" class="btn-primary mt-5">Modifier</button>
    </div>
    <SmallLoader v-if="isLoading" :text="'Chargement en cours'" class="mb-10" />
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue'
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import SmallLoader from '@/components/app/SmallLoader.vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  type: String,
  folder: [Array, Object],
  makeUnvisible: {
    Boolean,
    default: true,
  }
})

const itemRoles = ref([]);
const selectedPerson = ref([]);
const availableRoles = ref([]);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const roles = ref([]);
const componentKey = ref(0);
const isLoading = ref(false);
const selectedRole = ref(null);
const selectedAccess = ref(null);

const emit = defineEmits(['confirm']);

const rights = [
  { name: 'Non visible', value: 'unvisible' },
  { name: 'Lecture', value: 'read' },
  { name: 'Écriture', value: 'write' },
  { name: 'Suppression', value: 'delete' },
]
const changeRights = () => {
  if (selectedAccess.value.value === 'unvisible') {
    detachRole();
  } else {
    attachRole();
  }
}

const attachRole = async () => {
  let urlws = '';
  if (props.type === 'folder') {
    urlws = `api/folders/${props.folder.id}/share-with-role-training-action`;
  } else if (props.type === 'document') {
    urlws = `api/documents/${props.folder.id}/bulk-attach`;
  }
  try {
    const response = await client.post(`${urlws}`, {
      training_action_id: props.folder.training_action_id,
      role_name: selectedRole.value.name,
      access_level: selectedAccess.value.value,
    });
    showNotification.value = true;

    if (response.data.message.includes('Aucun utilisateur')) {
      notificationMessage.value = response.data.message;
      notificationType.value = 'error';
    } else {

      setTimeout(() => { showNotification.value = false; }, 3000);
      notificationType.value = 'success';
      notificationTitle.value = 'Bravo,';
      notificationMessage.value = response.data.message;
      selectedPerson.value = null;
    }
    emit('confirm');
    fetchRoles();
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Désolé, une erreur est survenue';
  }
}
const detachRole = async (role) => {
  let urlws = '';
  if (props.type === 'folder') {
    urlws = `api/folders/${props.folder.id}/remove-access-role`;
  } else if (props.type === 'document') {
    urlws = `api/documents/${props.folder.id}/bulk-detach`;
  }
  try {
    const response = await client.post(`${urlws}`, {
      training_action_id: props.folder.training_action_id,
      role_name: selectedRole.value.name,
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo,';
    notificationMessage.value = response.data.message;
    selectedPerson.value = null;
    emit('confirm');
    fetchRoles();
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Désolé, une erreur est survenue';
  }
}

const fetchRoles = async () => {
  isLoading.value = true;
  try {
    const response = await client.get('/api/roles');
    availableRoles.value = response.data;
    availableRoles.value = availableRoles.value.filter(role => role.name !== 'Administrateur\u00b7trice');
    isLoading.value = false;
  } catch (error) {
    errorMessage.value = 'Erreur lors de la récupération des rôles. ';
    if (error.response) {
      errorMessage.value += error.response.data.message;
    }
  }
}

onMounted(() => {
  if (!props.makeUnvisible) {
    rights.splice(rights.findIndex(right => right.value === 'unvisible'), 1);
  }
  fetchRoles();
});
</script>
