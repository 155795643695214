<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <AppLayout>
    <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
      <div class="min-w-0 flex-1">
        <div class="hero-structures max-w-[1520px] mx-auto">
          <h1 class="title1 text-5xl mb-5 !mt-20 text-white">
            Notes de {{ currentNote.role_name }}
          </h1>
          <h2 class="text-base text-indigo-600 mb-10 text-white">
            <span v-if="formStep?.date_created">Créé le {{ fetchEventServices.formatDateSimple(new
              Date(formStep?.date_created)) }}</span><span v-if="formStep?.date_updated"> - modifié le {{
                fetchEventServices.formatDateSimple(new Date(formStep?.date_updated)) }}</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-1 sm:pt-8 rounded-xl" id="trainingStep">
      <div class=" mx-auto max-w-[1100px] mt-20">
        <router-link class="" :to="'/etape/' + $route.params.idTrainingActionStep">
          <div class="flex items-center mb-10 hover:text-indigo-700">
            <ChevronLeftIcon class="h-8 w-8 flex-shrink-0 text-indigo-600 ml-1 sm:-ml-2" aria-hidden="true" /> Retour
            Fiche
          </div>
        </router-link>
        <p>{{ currentNote.content }}</p>

      </div>

    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import { ChevronLeftIcon, HomeIcon, CheckCircleIcon, CircleIcon, MinusCircleIcon } from '@heroicons/vue/20/solid';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchEventServices from '@/services/EventServices.js';

const route = useRoute();
const formStep = ref([]);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const trainingStep = ref([]);
const notesByRole = ref([]);
const currentNote = ref([]);

const getTrainigStep = async () => {
  try {
    const response = await client.get(`/api/training-action-step/${route.params.idTrainingActionStep}`);
    trainingStep.value = response.data;
    notesByRole.value = response.data.notes_by_roles;

    if (notesByRole.value && notesByRole.value?.length !== 0) {
      console.log('notesByRole', notesByRole.value, route.params.idRole);
      const note = notesByRole.value.find(note => note.role_id === Number(route.params.idRole));
      console.log('note', note);
      currentNote.value = note;
    }
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Creation Error';
    notificationMessage.value = 'Erreur dans l\'affichage de la note';
  }
}

onMounted(async () => {
  getTrainigStep();
});

</script>
<style scoped></style>
