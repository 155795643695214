<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <TransitionRoot as="template" :show="open">
    <Dialog class="relative z-10" @close="$emit('close')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>

                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 mb-10">Déplacer le {{ type
                    }}
                    <div v-if="type === 'folder'" class="text-indigo-600">" {{
                      folder.name }} "</div>
                    <div v-if="type === 'document'" class="text-indigo-600">" {{
                      document.name }} "</div>
                    <div>dans "<span class="text-indigo-600"> {{
                      folderSelected?.name }}</span> "</div>
                  </DialogTitle>

                  <form class="lg:block">{{ commonFolder.name }}
                    <button class="flex items-center border-b border-gray-200 pb-4 w-full"
                      v-if="!folders && training_action"
                      @click.prevent=" typeSelected = 'action'; folderSelected = training_action?.degree">
                      <FolderIcon class="h-8 w-8 mr-3 text-indigo-700" />
                      <p class="text-indigo-700 font-semibold">{{ training_action?.degree?.name }} - {{
                        training_action?.year }}
                      </p>
                    </button>
                    <button v-if="folders === '' && !training_action"
                      class="flex items-center border-b border-gray-200 pb-4 w-full"
                      @click.prevent=" typeSelected = 'common'; folderSelected = commonFolder[0]">
                      <FolderIcon class="h-8 w-8 mr-3 text-indigo-700" />
                      <p class="text-indigo-700 font-semibold">Documents communs </p>
                    </button>
                    <SmallLoader v-if="isLoading" :text="'Chargement en cours'" class="mt-5" />
                    <div v-for="relevantFolder in relevantFolders.folders" :key="relevantFolder.id">
                      <SubFolderAccordionMove :key="componentKey" :folder="relevantFolder" @select="selectFolder"
                        :folderSelected="folderSelected"
                        @click.prevent="folderSelected = childfolderSelected; typeSelected = 'folder'" />
                    </div>
                  </form>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button type="button"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  @click="moveItem(folder, folderSelected, typeSelected)">Déplacer</button>
                <button type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  @click="$emit('close')" ref="cancelButtonRef">Annuler</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import { onMounted, ref } from 'vue';
import { XMarkIcon, FolderIcon, DocumentIcon, ListBulletIcon } from '@heroicons/vue/24/outline'
import { ChevronLeftIcon, MinusIcon, PlusIcon } from '@heroicons/vue/20/solid'
import client from '@/api/client';
import { useRoute, useRouter } from 'vue-router';
import SubFolderAccordionMove from '@/components/files/SubFolderAccordionMove.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import SmallLoader from '../app/SmallLoader.vue';

const open = ref(true)

const props = defineProps({
  document: [Object, Array],
  action: [Number, String],
  folder: [Object, Array],
  type: { String },
  training_action: [Object, Array],
  folders: [Object, Array, String],
});

const commonFolder = ref([
  {
    id: null,
    name: 'Dossiers communs',
  },
]);
const route = useRoute();
const emit = defineEmits(['confirm', 'close']);
const folderSelected = ref(null);
const childfolderSelected = ref(null);
const actionSelected = ref(null);
const componentKey = ref(0);
const relevantFolders = ref([]);
const mobilefoldersOpen = ref(false);
const typeSelected = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const isLoading = ref(false);


const fetchFoldersByAction = async (action_id) => {
  isLoading.value = true;
  try {
    const response = await client.get(`/api/training-actions/${action_id}/folders`);
    relevantFolders.value = response.data;
    componentKey.value += 1;
    isLoading.value = false;
  } catch (error) {
    console.log(error);
  }
}

const selectFolder = (folder) => {
  childfolderSelected.value = folder;
}

const moveItem = () => {
  let parent_id = null;
  let training_action = null;
  if (typeSelected.value === 'folder') {
    parent_id = folderSelected.value.id;
  } else if (typeSelected.value === 'action') {
    parent_id = null;
    training_action = folderSelected.value.id;
  } else if (typeSelected.value === 'action') {
    parent_id = null;
  }

  if (props.type === 'folder') {
    putFolder(props.folder, parent_id);
  } else if (props.type === 'document') {
    putDocument(props.document, parent_id);
  }
}

const putFolder = async (from, to, action) => {
  try {
    const response = await client.put(`/api/folders/${from.id}`, {
      name: from.name,
      parent_id: to,
      // training_action_id: action,
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationMessage.value += '';
    emit('confirm');
    emit('close');
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Creation Error';
    notificationMessage.value = `Failed to create folder`;
  }
}

const putDocument = async (from, to, action) => {
  try {
    const response = await client.put(`/api/documents/${from.id}`, {
      name: from.name,
      folder_id: to,
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié';
    notificationMessage.value += '';
    emit('confirm');
    emit('close');
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Creation Error';
    notificationMessage.value = `Failed to create folder`;
  }
}

const fetchActions = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`/api/dashboard/training-actions-folders`);
    relevantFolders.value = response.data;
    componentKey.value += 1;
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    if (error.response?.data?.error) {
      errorMessage.value = error.response.data.error;
    } else {
      errorMessage.value = 'Une erreur est survenue';
    }
  }
}


onMounted(async () => {
  if (route.params.idAction) {
    fetchFoldersByAction(route.params.idAction);
  } else if (props.action) {
    fetchFoldersByAction(props.action);
  } else {
    relevantFolders.value = props.folders;
    if (!props.folders) {
      fetchActions();
    }
  }
});
</script>