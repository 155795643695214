<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <AppLayout>
    <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
      <div class="min-w-0 flex-1">
        <div class="hero-structures max-w-[1520px] mx-auto">
          <h1 class="title1 text-5xl mb-5 !mt-20 text-white">
            {{ formStep.name }}
          </h1>
          <h2 class="text-base text-indigo-600 mb-10 text-white">
            <span v-if="formStep?.date_created">Créé le {{ fetchEventServices.formatDateSimple(new
              Date(formStep?.date_created)) }}</span><span v-if="formStep?.date_updated"> - modifié le {{
                fetchEventServices.formatDateSimple(new Date(formStep?.date_updated)) }}</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-1 sm:pt-8 rounded-xl" id="trainingStep">
      <div class="mx-auto max-w-[1100px] mt-20">
        <router-link class="" :to="'/etape/' + $route.params.idTrainingActionStep">
          <div class="flex items-center mb-10 hover:text-indigo-700">
            <ChevronLeftIcon class="h-8 w-8 flex-shrink-0 text-indigo-600 ml-1 sm:-ml-2" aria-hidden="true" /> Retour
            Fiche
          </div>
        </router-link>

        <div v-for="section in formStep.sections" :key="section.title" class="mb-10">
          <h3 class="text-xl font-semibold leading-7 text-indigo-700 mb-5">{{ section.title }}</h3>
          <p v-if="section.description">{{ section.description }}</p>

          <div v-for="field in section.fields" :key="field.name">

            <div class="col-span-full"
              v-if="field.type === 'textarea' || field.type === 'text' || field.type === 'date' || field.type === 'number'">
              <label for="about" class="block text-base font-medium leading-6 text-gray-900">{{ field.label }}</label>
              <div class="mt-2 mb-8" v-if="field.type === 'textarea'">
                <div v-html="field.content"></div>
              </div>

              <div class="mt-2 mb-8" v-if="field.type === 'text' || field.type === 'date' || field.type === 'number'">
                <p>{{ field.content }}</p>
              </div>

            </div>
            <div class="" v-if="field.type === 'radio'">
              <div v-for="choice in field.choices" :key="choice.value">
                <div class="relative flex gap-x-3">
                  <div class="flex h-6 items-center mb-2">
                    <CheckCircleIcon class="h-6 w-6 text-emerald-300 mr-2" v-if="choice.label === field.content" />
                    <MinusCircleIcon class="h-6 w-6 text-gray-200 mr-2" v-else />
                    <p class="!mb-0">{{ choice.label }}</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import { ChevronLeftIcon, HomeIcon, CheckCircleIcon, CircleIcon, MinusCircleIcon } from '@heroicons/vue/20/solid';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import fetchEventServices from '@/services/EventServices.js';

const route = useRoute();
const formStep = ref([]);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const trainingStep = ref([]);

const getTrainigStep = async () => {
  try {
    const response = await client.get(`/api/training-action-step/${route.params.idTrainingActionStep}`);
    trainingStep.value = response.data;
    formStep.value = response.data.deliverables[0];
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Creation Error';
    notificationMessage.value = 'Erreur dans l\'enregistrement';
  }
}

onMounted(async () => {
  getTrainigStep();
});

</script>
<style scoped></style>
