<template>
  <main>
    <section id="hero">
      <header>
        <div class="mx-auto sm:px-6 lg:px-8 container-hero">
          <div class="flex flex-col lg:flex-row items-center">
            <div class="sm:w-100 lg:w-1/2 pr-10">
              <h1 class="font-bold text-white mb-10">
                S’engager dans la formation en alternance avec Trajectoire Formation
              </h1>
              <h2 class="text-white">C’est choisir de former et se former en situation de travail, choisir
                de rendre le travail apprenant</h2>
              <a href="#video" class="rounded-full button px-5 py-3 mt-10 inline-block hover:bg-emerald-400">Découvrir
                la Fest</a>
            </div>
            <div class="sm:w-100 lg:w-1/2">
              <div class="grid grid-cols-2 w-full gap-8">
                <div class=""><img class="object-cover h-96 w-full mt-36 rounded-3xl img1" alt=""
                    src="@/assets/img/photos/trajectoire-fest-atelier-musical2.jpg"></div>
                <div class=""><img class="object-cover h-80 w-full mb-8 rounded-3xl img2" alt=""
                    src="@/assets/img/photos/trajectoire-fest-atelier-musical1.jpg"><img alt=""
                    class="object-cover h-96 w-full rounded-3xl img3"
                    src="@/assets/img/photos/trajectoire-fest-jeu.jpg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
    <section id="" class="!px-6 sm:!px-10 mx-auto !max-w-unset lg:max-w-5xl xl:max-w-7xl 2xl:max-w-9xl">
      <div class="flex flex-col lg:flex-row">
        <div class="sm:w-100 lg:w-2/5 order-2 lg:order-1 mt-10 lg:mt-0">
          <div class="card card-blue mt-0">
            <p class="title4">« Ce qu’on met en place pour les apprenants de Trajectoire Formation, va
              servir aussi à l’accueil de nouveaux salariés et faire évoluer les structures, pour
              aller vers l’organisation apprenante. Quand on parle du travail réel via la FEST, on
              fait place à des échanges sur le travail. " </p>
          </div>
          <img src="@/assets/img/elements/fest-triangle.png" alt="Trajectoire FEST schéma"
            class="w-full max-w-80 mx-auto mt-10">
        </div>
        <div class="sm:w-100 lg:w-3/5 pl-0 lg:pl-10 order-1 lg:order-2 mt-0 lg:mt-10">
          <p class="text-body">Trajectoire Formation s’est lancé depuis l’été 2022 dans un ambitieux
            chantier, visant à développer la Formation en Situation de Travail dans nos formations
            diplômantes.
            L'enjeu se situe au-delà d'une question de pédagogie, c'est une nouvelle relation entre
            organisme de formation, structures apprenantes et apprenants qui se tissent. Il s'agit
            également d'introduire une discussion sur ce qui rend le travail apprenant et de partager
            ces réflexions dans une "communauté d'apprentissage.</p>
          <h3 class="title3">La FEST c’est quoi ?</h3>
          <p class="text-body">La FEST consiste à développer au sein des structures des parcours,
            alternant des temps de mises en situation de travail aménagé et des temps de réflexivité.
            Nous avons identifié la situation "Entrer en relation avec..." comme situation apprenante
            pour les FEST mises en œuvre dans les parcours de formation diplômante d'animateur.trice.
            Ces séquences sont des temps de formation qui ont lieu dans la structure d'alternance en
            situation "réelle". Une fois la situation vécue par l'apprenant, elle est traitée grâce à un
            entretien réflexif mené par le formateur métier et/ou le formateur référent.</p>
          <h3 class="title3">Devenir Formateur Métier</h3>
          <p class="text-body">C'est contribuer à l'apprentissage d'un métier pour un.e apprenant.e à
            partir du travail tel qu'il est mené dans le contexte de ma structure. C'est également
            bénéficier de ces temps pour réinterroger nos pratiques (ex : comment l'accueil des parents
            se fait le matin en accueil périscolaire).
            Et enfin, c'est rejoindre une communauté d'apprentissage, où je peux échanger avec les
            autres formateurs métiers et m'enrichir des réflexions sur le travail apprenant.
          </p>
        </div>
      </div>
    </section>
    <section id="video" class="mx-auto py-0">
      <iframe width="1204" height="677" class="mx-auto relative z-1" src="https://www.youtube.com/embed/GElvZn62huE"
        title="Point d'étape sur la recherche-action FEST de Trajectoire Formation" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen=""></iframe>

      <div id="situations" class="relative z-1 container mx-auto lg:max-w-5xl xl:max-w-5xl 2xl:max-w-6xl pt-14">
        <div class="flex flex-col lg:flex-row items-center">
          <div class="lg:w-2/5">
            <p class="title3 text-left lg:text-right pb-6">Des exemples de mises en situation
              réalisées
              pour la séquence FEST "Entrer en relation" </p>
          </div>
          <div class="lg:w-3/5 pl-0 sm:pl-14">
            <p class="text-body">
            </p>
            <ul class="list-disc">
              <li>Entrer en relation avec un résident plutôt « réservé » via un entretien individuel
                dans une structure accueillant des adultes en situation de handicap</li>
              <li>Entrer en relation avec des parents venant chercher leur enfant le soir en accueil
                périscolaire</li>
              <li>Entrer en relation avec des ados rejoignant pour la première fois un accueil jeunes
                pendant les vacances</li>
            </ul>
            <p></p>
          </div>
        </div>
      </div>
    </section>

    <section id="videos">
      <div class="mx-auto container-minicarousel">
        <ul class="control" id="custom-control" aria-label="Carousel Navigation" tabindex="0">
          <li class="prev" aria-controls="tns1" tabindex="-1" data-controls="prev" aria-disabled="true">
            <span class="icon-left"><img src="@/assets/img/elements/left.svg" alt="précédent"></span>
          </li>
          <li class="next" aria-controls="tns1" tabindex="-1" data-controls="next">
            <span class="icon-right"><img src="@/assets/img/elements/right.svg" alt="précédent"></span>
          </li>
        </ul>
        <div class="my-slider">
          <div class="slider-item">
            <iframe width="384" height="277" class="mx-auto" src="https://www.youtube.com/embed/1qivrzDVVe8"
              title="Video 1" frameborder="0" allowfullscreen></iframe>
            <div class="name">Coralie</div>
            Coralie entre en relation avec une maman et ses enfants
          </div>
          <div class="slider-item">
            <iframe width="384" height="277" class="mx-auto" src="https://www.youtube.com/embed/w1cLBPm4mao"
              title="Video 2" frameborder="0" allowfullscreen></iframe>
            <div class="name">Amandine</div>
            Amandine anime un rituel de démarrage pour faire groupe et accueillir chaque enfant.
          </div>
          <div class="slider-item">
            <iframe width="384" height="277" class="mx-auto" src="https://www.youtube.com/embed/evgsTf9hB7A"
              title="Video 3" frameborder="0" allowfullscreen></iframe>
            <div class="name">Sarah</div>
            Sarah se demande comment intégrer une nouvelle personne à un groupe.
          </div>
          <div class="slider-item">
            <iframe width="384" height="277" class="mx-auto" src="https://www.youtube.com/embed/u3vApQSWCXM"
              title="Video 4" frameborder="0" allowfullscreen></iframe>
            <div class="name">Coralie</div>
            Coralie choisit d'approfondir le lien avec une maman et doit donc s'appuyer sur l'équipe
            pour prendre le relais sur l'accueil des autres parents.
          </div>
        </div>
      </div>
    </section>

    <section id="citations" class="container mx-auto lg:max-w-5xl xl:max-w-6xl 2xl:max-w-8xl gap-4">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-4">
        <div class="">
          <h3 class="title3">Ils nous racontent</h3>
          <div class="card card-blue my-4">
            <p class="title4">Organiser autrement la rencontre entre structures et apprenants, en
              faisant raconter par le travail réel tel qu’il est vécu par la structure</p>
          </div>
          <div class="card card-green mb-0">
            <p class="title4">« Ça a été vraiment une réelle remise en question pour moi. Ça m'a permis
              de me poser. Pour moi la FEST c'est porter un autre regard sur ma posture. »</p>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="card card-green order-2 sm:order-1">
            <p class="title4">« J’ai vécu la situation avec elle, mais je n’avais pas vu tout le
              cheminement qu’elle avait fait, en racontant elle a vu tous les bénéfices que ça lui a
              apporté. Elle a aussi pris conscience de tout ce qu’elle avait fait. Ça parait anodin
              mais ensuite, sur la journée ça a apporté une vraie dynamique. » </p>
          </div>
          <div class="card card-pink order-1 sm:order-2">
            <p class="title4">« La FEST organise des temps réflexifs qui permet à l’apprenante de
              s’auto-positionner, plutôt que ce soit nous qui nous lui renvoyons les éléments. »</p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import { onMounted } from 'vue';
import { tns } from 'tiny-slider';
import 'tiny-slider/dist/tiny-slider.css';

// Initialize tiny-slider after the component is mounted
onMounted(() => {
  tns({
    container: '.my-slider',
    items: 3,
    gutter: 30,
    slideBy: 'page',
    // autoplay: true,
    controls: true,
    // nav: true,
    controlsPosition: 'bottom',
    navPosition: 'bottom',
    mouseDrag: true,
    autoplay: false,
    autoplayButtonOutput: false,
    controlsContainer: '#custom-control',
    loop: false,
    edgePadding: 20,
    // startIndex: 0,
    nav: false,
    arrowKeys: true,
    animateIn: 'scale',
    swipeAngle: false,
    speed: 600,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      576: {
        items: 1,
        nav: true,
      },
      650: {
        items: 2,
        nav: true,
      },
      1440: {
        items: 3,
      },
    },
  });
});

</script>

<style scoped>
body {
  font-family: "General", serif;
  font-weight: 300;
  font-style: normal;
  font-optical-sizing: auto;
  font-variation-settings:
    "wdth" 300;
}

#hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 5.5rem 1rem 4rem 2rem;
}

#hero h1 {
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: 400;
}

#hero h2 {
  font-size: 1.7rem;
  font-family: "Clash";
  font-weight: 300;
  letter-spacing: 1px;
}

.logo img {
  width: 200px;
  height: auto;
}

button,
.button {
  background-color: #4EF4B9;
  font-family: "Clash";
  font-weight: 400;
  letter-spacing: 0.8px;
}

.text-body {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #565656;
  font-family: "Clash";
  font-weight: 300;
  letter-spacing: 1px;
}

.title3 {
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #075CD0;
  line-height: 1.5;
}

.title4 {
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: 350;
  font-variation-settings:
    "wdth" 350;
}

.title4 b {
  font-weight: 400;
  font-variation-settings:
    "wdth" 400;
}

.title-block {
  text-align: center;
  font-size: 3rem;
}

.card {
  border-radius: 8px;
  padding: 2rem;
  margin: 2rem 1rem;
}

.card .title4 {
  font-size: 1.3rem;
}

.card-blue {
  background-image: linear-gradient(128deg, #581EFF 18%, #25D4EB 65%, #25CEEB 89%, #25E6EB 100%);
  color: #fff;
}

.card-green {
  background-image: linear-gradient(131deg, #1BF5C8 35%, #14CCF3 74%, #046BFA 100%);
  color: #000;
}

.card-pink {
  background-image: linear-gradient(220deg, #F54CF7 23%, #581EFF 79%, #2587EB 88%, #25D4EB 100%);
  border-radius: 8px;
  color: #fff;
}

.container-hero {
  max-width: 100rem;
}

.menu-item {
  font-size: 1.2rem;
  font-weight: 300;
  font-family: "Clash", sans-serif;
  letter-spacing: 1px;
}

#alternance_day {
  background-image: linear-gradient(114deg, #F54CF7 0%, #581EFF 33%, #2563EB 49%, #19abbe 74%, #11bfc5 100%);
  color: #fff;
  padding: 6rem 3rem 5rem;
}

#alternance_day .text-body {
  color: #fff;
}

#video {
  padding-top: 10rem;
  /* background-image: url('../img/elements/bg-video.svg'); */
  background-position: center center;
  background-size: 100% 1000px;
  background-repeat: no-repeat;
}

.container-minicarousel {
  max-width: 100rem;
}

#video iframe {
  width: 100%;
  max-width: 80rem;
}

.bg-video {
  max-height: 500px;
}

.name {
  margin-top: 1rem;
  color: #FF47FE;
  font-weight: 500;
  font-size: 1.2rem;
}

section {
  padding: 4rem 3rem;
}

#situations li {
  margin-bottom: 1.5rem;
}

#situations .title3 {
  font-size: 1.8rem;
}

footer {
  padding: 2rem;
}

.img1 {
  height: 23rem;
}

.img2 {
  height: 17rem;
}

.img3 {
  height: 20rem;
}

@media (max-width: 1023px) {
  section {
    padding: 2rem 2rem;
  }
}

@media (max-width: 1023px) {
  .container {
    max-width: unset;
  }

  .card {
    margin: 1rem 0 0;
  }
}
</style>
