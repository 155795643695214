<template>
  <nav class="mx-auto" aria-label="Progress">
    <ol role="list" class="overflow-hidden rounded-md lg:flex lg:rounded-none">
      <li v-for="( step, stepIdx ) in steps" :key="step.id" class="relative overflow-hidden lg:flex-1">
        <div class="absolute inset-0 lg:flex items-start z-0 hidden mt-16" aria-hidden="true">
          <div class="w-full bg-gray-400" style="margin-top: -1.8rem; height:1px;" />
        </div>
        <div
          :class="[stepIdx === 0 ? 'rounded-t-md border-b-0' : '', stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '', 'overflow-hidden border lg:border-0']">
          <router-link :to="'/etape/' + step.id">
            <span :class="[stepIdx !== 0 ? 'lg:pl-3' : '', 'lg:pl-6 flex lg:flex-col px-3 py-5 text-sm']">
              <span class="flex-shrink-0" v-if="(new
                Date(step.end_datetime) < new Date()) && step.end_datetime">
                <span class="relative z-10 flex h-7 w-7 items-center justify-center rounded-full bg-indigo-600">
                  <CheckIcon class="h-4 w-4 text-white stroke-2 font-medium" aria-hidden="true" />
                </span>
              </span>
              <span class="flex-shrink-0" v-else>
                <span
                  class="relative z-10 flex h-7 w-7 items-center justify-center rounded-full border border-gray-400 bg-white"
                  :class="(step.trainee_documents.length > 0 || step.notes_by_roles || step.deliverables) ? '!bg-green-50 border-green-400' : ''">
                  <span class="text-gray-600"
                    :class="(step.trainee_documents.length > 0 || step.notes_by_roles || step.deliverables) ? 'text-green-600 font-medium' : ''">{{
                      step.order }}</span>
                </span>
              </span>
              <span class="pl-4 lg:pl-0 mt-0.5 lg:mt-2 flex min-w-0 flex-col">
                <span class="text-sm text-gray-600"
                  :class="(step.trainee_documents.length > 0 || step.notes_by_roles || step.deliverables) ? 'font-medium' : ''">{{
                    step.name }}</span>
                <span class="text-sm text-gray-500" v-if="step.start_datetime">{{
                  fetchEventServices.formatDateSimple(new
                    Date(step.start_datetime)) }}</span>
                <p class="font-medium text-indigo-600 mt-2"> <span
                    class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                    v-if="(step.trainee_documents.length > 0 || step.notes_by_roles || step.deliverables)">
                    <CheckIcon class="w-4 h-4" />
                    <span class="ml-1">contenu
                      déposé</span>
                  </span><span v-if="step.folders.length === 0"
                    class="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                    <FolderIcon class="w-4 h-4" />
                  </span></p>
              </span>
            </span>
          </router-link>
          <template v-if="stepIdx !== 0">
            <!-- Separator -->
            <div class="absolute inset-0 left-0 top-0 hidden w-1 lg:block" aria-hidden="true">
            </div>
          </template>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed, inject } from 'vue';
import { CheckIcon } from '@heroicons/vue/16/solid';
import fetchEventServices from '@/services/EventServices.js';
import { FolderIcon, ChatBubbleBottomCenterTextIcon, PencilSquareIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  student: Object,
  steps: Array,
});

onMounted(() => {
});

</script>