<template>
    <Disclosure as="nav" class="" v-slot="{ open }">
        <div class="mx-auto px-4 sm:px-6 lg:px-8 md:py-8">
            <div class="flex h-16 justify-between w-full">
                <div class="flex lg:w-full order-2">
                    <div class="flex flex-shrink-0 items-center">
                        <a href="/" alt="logo Trajectoire fest"><img class="h-9 sm:h-12 md:h-16 w-auto"
                                src="@/assets/img/logos/logo.png" alt="Trajecoire Fest"></a>
                    </div>
                    <div class="hidden lg:ml-auto lg:flex lg:space-x-8">
                        <a v-for="itemNavigation in enhancedNavigation" :key="itemNavigation.name"
                            :href="itemNavigation.href" class="inline-flex items-center border-b-2  border-transparent px-1 pt-1 text-md text-white
                            hover:border-cyan-200 hover:text-cyan-200" :target="itemNavigation.target"
                            :class="{ '!border-indigo-600 text-gray-900 font-medium': itemNavigation.current, '': !itemNavigation.current }">
                            {{ itemNavigation.name }}
                        </a>
                    </div>
                </div>
                <div class=" lg:ml-6 flex items-center order-3">
                    <div v-if="canSelect || niu">
                        <router-link
                            :to="'/profil/structures-selectionnees/' + currentProfile.training_actions[0]?.event_id"
                            v-if="currentProfile?.role?.name === 'Apprenant·e' && currentProfile?.training_actions[0]">
                            <button type="button"
                                class="relative rounded-full bg-white p-1 text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <span class="absolute -inset-1.5" />
                                <span class="sr-only">Voir les notifications</span>
                                <div class="group -my-2 flex items-center p-2 text-gray-500">
                                    <StarIcon class="h-6 w-6" aria-hidden="true" />
                                    <span
                                        class="ml-2 text-sm font-medium text-gray-700 group-hover:text-indigo-100 bg-red-500 rounded-full px-2 text-white">{{
                                            selectedStructuresCount }}</span>
                                    <span class="sr-only">voir le panier de structure sélectionnées</span>
                                </div>
                            </button></router-link>
                    </div>

                    <!-- currentProfile dropdown -->
                    <Menu as="div" class="relative ml-3" v-if="currentProfile">
                        <div>
                            <MenuButton
                                class="relative flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                v-if="currentProfile">
                                <span class="absolute -inset-1.5" />
                                <span class="sr-only">Ouvrir le menu</span>
                                <span class="flex items-center max-w-52"> <img class="h-8 w-8 rounded-full"
                                        :src="currentProfile.profile_picture_url" alt=""
                                        v-if="currentProfile.profile_picture_url">
                                    <UserCircleIcon class="h-8 w-8  text-white" aria-hidden="true" v-else />
                                    <div class="text-left leading-5 pl-3  hidden sm:block text-white"
                                        v-if="currentProfile">
                                        <div class="font-medium">{{ currentProfile.user.name }}</div>
                                        {{ currentProfile.role.name }}
                                    </div>
                                    <ChevronDownIcon class="ml-2 h-5 w-5 text-white min-w-5" aria-hidden="true" />
                                </span>
                            </MenuButton>
                        </div>
                        <transition enter-active-class="transition ease-out duration-200"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                            <MenuItems
                                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <MenuItem v-slot="{ active }" v-for=" itemProfile in menuProfileItems "
                                    :key="itemProfile.name">
                                <router-link :to="itemProfile.href"
                                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{
                                        itemProfile.name }}</router-link>
                                </MenuItem>
                                <MenuItem v-if="isLoggedIn" v-slot="{ active }">
                                <a @click.prevent="logoutUser" @keydown.enter.prevent="logoutUser"
                                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                                    Déconnexion
                                </a>
                                </MenuItem>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
                <div class=" lg:ml-6 flex items-center order-3" v-if="!currentProfile">
                    <div class="py-6">
                        <router-link to="/authentification"
                            class="-mx-3 block rounded-full bg-cyan-300 mr-2 px-3 py-2.5 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                            <span class="sr-only">Se connecter</span>
                            <LockClosedIcon class="h-5 w-5" aria-hidden="true" />
                        </router-link>
                    </div> <router-link to="/utilisateurs/profil/creer-mon-profil"><button type="button"
                            class="rounded-full bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            Créer un compte</button></router-link>
                </div>
                <div class="-mr-2 flex items-center lg:hidden order-1">
                    <!-- Mobile menu button -->
                    <DisclosureButton
                        class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span class="absolute -inset-0.5" />
                        <span class="sr-only">Ouvrir le menu général</span>
                        <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                        <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                    </DisclosureButton>
                </div>
            </div>
        </div>

        <DisclosurePanel class="lg:hidden">
            <div class="space-y-1 pb-3 pt-2">
                <DisclosureButton as="a" v-for="   itemNavigation in navigationItems " :key="itemNavigation.name"
                    :href="itemNavigation.href" :target="itemNavigation.target" :class="itemNavigation.current
                        ? 'bg-indigo-50 border-indigo-600 text-indigo-700'
                        : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                        "
                    class="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700">
                    {{ itemNavigation.name }}</DisclosureButton>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, inject, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
    Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { Bars3Icon, XMarkIcon, StarIcon, LockClosedIcon } from '@heroicons/vue/24/outline';
import { UserCircleIcon } from '@heroicons/vue/24/solid';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const selectedStructures = computed(() => store.selectedStructures);

const router = useRouter();
const logoutUser = async () => {
    await store.logoutUser();
    store.clearStructures();
    router.push({ name: 'Catalogue de structures' });
};

const route = useRoute();

const navigationItems = [
    { name: 'Accueil', href: '/', target: '_parent', current: false, auth: null, },
    { name: 'Structures', href: '/structures', target: '_parent', current: false, auth: null, },
    { name: 'L\'alternance Day', href: '/prochain-evenement', target: '_parent', current: false, auth: null, },
];

const myProfileIdUrl = '/utilisateurs/profil/' + store.chosenProfile?.id;
const mySelectionUrl = '/profil/structures-selectionnees/' + store.chosenProfile?.training_actions[0]?.event_id;

// Todo: add dynamic id
const profileItems = [
    { name: 'Mon profil', href: '/tableau-de-bord', current: true, auth: 'all' },
    { name: 'Ma page publique', href: myProfileIdUrl, dynamic: 'idProfile', current: true, auth: 'all' },
    { name: 'Mes documents', href: '/documents', current: true, auth: 'all' },
];

let menuProfileItems = ref([]);
const enhancedNavigation = computed(() => navigationItems.map((item) => ({
    ...item,
    current: item.href === '/' ? route.path === item.href : route.path.startsWith(item.href),
})));

const selectedStructuresCount = computed(() => store.selectedStructures.length);
const isLoggedIn = computed(() => !!store.chosenProfile);


const niu = computed(() => {
    if (!currentProfile.role) {
        return true;
    } else {
        return false;
    }
});
const canSelect = computed(() => {
    if (currentProfile?.role) {
        if (currentProfile.role.name === 'Apprenant·e') {
            return true;
        }
    } else {
        return false;
    }
});
const subMenuItems = () => {
    menuProfileItems = profileItems.filter(item => {
        if (currentProfile.value?.role?.name === 'Administrateur·trice' && item.name === 'Ma page publique') {
            return false;
        }
        return item.auth === 'all' || currentProfile.value?.role?.id == item.auth;
    });
};

watch(() => store.chosenProfile, (newProfile) => {
    currentProfile.value = newProfile;
    menuProfileItems = profileItems;
    subMenuItems();
});
onMounted(() => {
    subMenuItems();
    store.setStructures();
});

</script>
