<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <AppLayout>
    <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
      <div class="min-w-0 flex-1">
        <div class="hero-structures max-w-[1520px] mx-auto">
          <h1 class="title1 text-5xl mb-5 !mt-20 text-white">
            Commentaires
          </h1>
          <h2>{{ }}</h2>
        </div>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-1 sm:pt-8 rounded-xl" id="trainingStep">
      <div class=" mx-auto max-w-[1100px] mt-20">
        <router-link class="" :to="'/etape/' + $route.params.idTrainingActionStep">
          <div class="flex items-center mb-10 hover:text-indigo-700">
            <ChevronLeftIcon class="h-8 w-8 flex-shrink-0 text-indigo-600 ml-1 sm:-ml-2" aria-hidden="true" /> Retour
            Fiche
          </div>
        </router-link>

        <div class="mb-10">
          <h3 class="text-xl font-semibold leading-7 text-indigo-700 mb-5">Vous pouvez ajouter un commentaire</h3>

          <div class="col-span-full">
            <div class="mt-2 mb-8">
              <textarea id="comment" name="comment" rows="20" placeholder="Saissez votre commentaire ici"
                v-model="currentNote"
                class="min-h-auto block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-8" />
            </div>
          </div>
        </div>
        <div class="flex mx-auto mb-10">
          <button class="btn btn-primary ml-auto" @click.prevent="editStep">
            enregistrer
          </button>
        </div>
      </div>

    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import { ChevronLeftIcon, HomeIcon } from '@heroicons/vue/20/solid'
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const route = useRoute();
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const store = inject('store');
const currentProfile = ref(store.chosenProfile);

const currentNote = ref([]);
const notesByRole = ref([]);
const trainingStep = ref([]);

const editStep = async () => {
  let arrayToPost = {};
  const notes = {
    role_id: currentProfile.value.role.id,
    role_name: currentProfile.value.role.name,
    content: currentNote.value,
  };
  if (notesByRole.value) {
    console.log('notesByRole.value', notesByRole.value);
    const indexToChange = notesByRole.value.findIndex(note => note.role_id === currentProfile.value.role.id);
    console.log('indexToChange', indexToChange);
    if (indexToChange !== -1) {
      notesByRole.value[indexToChange] = {
        role_id: currentProfile.value.role.id,
        role_name: currentProfile.value.role.name,
        content: currentNote.value,
      }
    } else {
      notesByRole.value.push(notes);
    }

  } else {
    notesByRole.value = [notes];
  }
  arrayToPost.notes_by_roles = notesByRole.value;

  try {
    const response = await client.put(`/api/training-action-step/${route.params.idTrainingActionStep}`, arrayToPost);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = response.data.message;
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Creation Error';
    notificationMessage.value = `Erreur dans l'enregistrement`;
  }
}

const getTrainigStep = async () => {
  try {
    const response = await client.get(`/api/training-action-step/${route.params.idTrainingActionStep}`);
    trainingStep.value = response.data;
    notesByRole.value = response.data.notes_by_roles;

    if (notesByRole.value && notesByRole.value?.length !== 0) {
      const note = notesByRole.value.find(note => note.role_id === currentProfile.value.role.id);
      currentNote.value = note.content;
    }
  } catch (error) {
    console.log('error', error);
  }
}

onMounted(async () => {
  getTrainigStep();

});

</script>
<style scoped></style>
