<template>
  <DocumentLayout>
    <template v-slot:title v-if="folderSelected">{{ folderSelected.name }}</template>
    <template v-slot:nav>
      <NavFiles :action="folderSelected?.training_action_id" :folder="folderSelected?.id" :key="componentKey"
        @changePage="fetchFolder" v-if="folderSelected" />
    </template>
    <template v-slot:main>
      <SmallLoader v-if="isLoading" :text="'Chargement en cours'" class="mb-10" />
      <AlertMessage v-if="notAuthorized" :type="'error'" :title="errorMessage" class="w-full"
        @close="notAuthorized = false" />
      <FolderManager :folder="folderSelected" :key="componentKey" @update="fetchFolder" v-if="folderSelected" />
    </template>
  </DocumentLayout>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, watch } from 'vue';
import DocumentLayout from '@/layouts/DocumentLayout.vue';
import FolderManager from '@/components/files/FolderManager.vue';
import client from '@/api/client';
import { useRoute } from 'vue-router';
import NavFiles from '@/components/files/NavFiles.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';
import AlertMessage from '@/components/alert/AlertMessage.vue';

const route = useRoute();

const componentKey = ref(0);
const folderSelected = ref(null);
const isLoading = ref(true);
const notAuthorized = ref(false);
const errorMessage = ref('');

const fetchFolder = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`/api/folders/${route.params.idFolder}`);
    folderSelected.value = response.data;
    componentKey.value += 1;
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    notAuthorized.value = true;
    if (error.response?.data?.error) {
      errorMessage.value = error.response.data.error;
    } else {
      errorMessage.value = 'Une erreur est survenue';
    }
  }
}

onMounted(() => {
  fetchFolder();
});
</script>
