<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol role="list" class="flex flex-wrap items-center space-x-2">
      <li>
        <div>
          <a href="/documents/"
            class="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700 max-w-60 text-indigo-600 underline flex">
            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" /> <span class="ml-2">Tous les documents</span>
            <span class="sr-only">Documents</span>
          </a>
        </div>
      </li>

      <li v-for="(page, index) in item" :key="page.name">

        <div class="flex items-center">
          <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"
            v-if="(index + 1) <= item.length" />
          <a :href="'/documents/action/' + page.id"
            class="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700 max-w-60"
            :class="route.path === ('/documents/dossier/' + page.id) ? '' : 'text-indigo-600 underline'"
            v-if="page.type === 'training_action'" :aria-current="page.current ? 'page' : undefined">{{ page.name }}</a>
          <a :href="'/documents/dossier/' + page.id" class="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700"
            :class="route.path === ('/documents/dossier/' + page.id) ? '' : 'text-indigo-600 underline'"
            v-if="page.type === 'folder'" :aria-current="page.current ? 'page' : undefined">{{ page.name }}</a>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import { ChevronRightIcon, HomeIcon } from '@heroicons/vue/20/solid'
import { useRoute } from 'vue-router';

const route = useRoute();

const props = defineProps({
  item: [Object, Array]
})

onMounted(async () => {
});
</script>
