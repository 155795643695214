<template>
    <div class="md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1 mt-5 mb-5">
            <h2 class="text-2xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">Ma
                promotion
            </h2>
        </div>
    </div>
    <div class="" v-if="currentProfile">
        <TrainingActionStepsTimeline v-if="trainingOngoing === 1 || actionsPast"
            :profileStudentId="currentProfile.id" />
        <CurrentTrainingActions v-if="trainingOngoing === 0" :actionsPast="actionsPast" />
        <div class="mt-10">
            <NewDocuments />
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, inject, watch, computed, onMounted } from 'vue';
import CurrentTrainingActions from '@/components/training-actions/CurrentTrainingActions.vue';
import TrainingActionStepsTimeline from '@/components/steps/TrainingActionStepsTimeline.vue';
import NewDocuments from '@/components/files/NewDocuments.vue';
import client from '@/api/client';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const profileId = store.chosenProfile.id;
const currentTrainingActions = ref([]);
const trainingOngoing = ref(null);
const componentKey = ref(0);
const lastAction = ref(null);
const actionsPast = ref(false);

watch(() => store.chosenProfile, (newProfile) => {
    currentProfile.value = newProfile;
});

const fetchCurrentTrainingActions = async () => {
    const response = await client.get(`/api/training-actions/profile/${profileId}`);
    currentTrainingActions.value = response.data;
    if (currentTrainingActions.value.length !== 0) {
        lastAction.value = currentTrainingActions.value[currentTrainingActions.value.length - 1];
        if (lastAction.value.is_ongoing) {
            trainingOngoing.value = 1;
            componentKey.value += 1;
        } else {
            trainingOngoing.value = 0;
            if (new Date(lastAction.value.end_date) < new Date()) {
                actionsPast.value = true;
                componentKey.value += 1;
            }
        }
    } else {
        trainingOngoing.value = 0;
    }
};

onMounted(async () => {
    fetchCurrentTrainingActions();
});
</script>
