<template>
  <div class="flex flex-wrap gap-1">
    <a v-for="person in users" :key="person.email" :href="person.href" class="relative rounded-full hover:opacity-75">
      <img class="inline-block h-8 w-8 rounded-full" :src="person.imageUrl" :alt="person.name" v-if="person.img" />
      <UserCircleIcon v-else class="h-8 w-8 text-gray-200" aria-hidden=" true" />
    </a><button @click="displayList = !displayList" class="btn-read inline">Voir la liste</button>
  </div>
  <div v-if="displayList" class="py-5">
    <div v-for="person in users" :key="person.email">
      {{ person.name }} - {{ person.pivot?.access_level }}
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import client from '@/api/client';
import { UserCircleIcon } from '@heroicons/vue/24/solid';

const props = defineProps({
  type: String,
  item: [String, Number],
})
const users = ref([]);
const displayList = ref(false);

const getUsers = async () => {
  let urlws = '';
  if (props.type === 'document') {
    urlws = `/api/documents/${props.item}/users`;
  } else if (props.type === 'folder') {
    urlws = `api/folders/${props.item}/users`;
  }
  try {
    const response = await client.get(`${urlws}`);
    users.value = response.data;
  } catch (error) {
    users.value = [];
  }
}

onMounted(() => {
  getUsers();
});
</script>
