<template>
  <DocumentLayout>
    <template v-slot:title>Tous mes dossiers</template>
    <template v-slot:full>
      <div class="mt-4 lg:-mt-16">
        <button class="ml-auto btn-read mb-10" @click.prevent="displaySelectTraining = !displaySelectTraining"
          v-if="!trainingOngoing && currentTrainingActions?.length !== 0"><span v-if="!displaySelectTraining">Je
            m'ajoute à un
            nouvelle
            action de
            formation</span><span v-else>Fermer</span></button>
        <div class=" bg-white shadow sm:rounded-lg p-4 mb-5" v-if="displaySelectTraining">
          <div class="border-l-4 border-yellow-400 bg-yellow-50 p-4 mb-10" v-if="noAction">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <p class="text-base text-yellow-700 font-medium">Aucune action de formation disponible pour cet
                  utilisateur
                </p>
              </div>
            </div>
          </div>
          <SelectTrainingAction @confirm="fetchCurrentTrainingActions" class="mb-5" />
        </div>

        <SmallLoader v-if="isLoading" :text="'Chargement en cours'" class="mb-10" />
        <ActionsManager :folders="relevantTrainingActions" :key="componentKey" @update="fetchActions"
          v-if="relevantTrainingActions" />
      </div>
    </template>
  </DocumentLayout>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import DocumentLayout from '@/layouts/DocumentLayout.vue';
import ActionsManager from '@/components/files/ActionsManager.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';
import client from '@/api/client';
import SelectTrainingAction from '@/components/training-actions/SelectTrainingAction.vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';

const relevantTrainingActions = ref([]);
const componentKey = ref(0);
const isLoading = ref(true);
const errorMessage = ref('');

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const profileId = store.chosenProfile.id;
const currentTrainingActions = ref([]);
const noAction = ref(false);
const displaySelectTraining = ref(false);
const lastAction = ref(null);
const trainingOngoing = ref(true);

const fetchActions = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`/api/dashboard/training-actions-folders`);
    relevantTrainingActions.value = response.data;
    componentKey.value += 1;
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    if (error.response?.data?.error) {
      errorMessage.value = error.response.data.error;
    } else {
      errorMessage.value = 'Une erreur est survenue';
    }
  }
}
const fetchCurrentTrainingActions = async () => {
  const response = await client.get(`/api/training-actions/profile/${profileId}`);
  currentTrainingActions.value = response.data;
  if (currentTrainingActions.value.length !== 0) {
    lastAction.value = currentTrainingActions.value[currentTrainingActions.value.length - 1];
    if (lastAction.value.is_ongoing) {
      displaySelectTraining.value = false;
      componentKey.value += 1;
      trainingOngoing.value = true;
    } else {
      trainingOngoing.value = false;
    }
  } else {
    noAction.value = true;
    displaySelectTraining.value = true;
    trainingOngoing.value = false;
  }
};

onMounted(() => {
  fetchActions();
  if (currentProfile.value.role.name === 'Apprenant·e') {
    fetchCurrentTrainingActions();
  }
});
</script>
