<template>
    <AppLayout>
        <div class="mx-auto 2xl:max-w-9xl px-4 detail-page">
            <div class="hero-structures max-w-[1520px] mx-auto">
                <div class=" lg:flex items-end">
                    <div>
                        <h1 class="title1 mb-5 lg:mb-8 text-white">Mes vœux d'alternance</h1>
                        <p class="max-w-[900px] text-md md:text-lg lg:text-xl lg:leading-8 lg:!pr-5 text-white"> <span
                                class="font-medium text-cyan-300">Pour
                                choisir
                                dans quelle structure tu pourras réaliser ton alternance,
                                il faut que tu émettes des vœux
                                de structures. Pour cela, il faut que tu choisisses dans
                                le catalogue les 3
                                structures pour lesquelles tu souhaites candidater en priorité, et les 2 en
                                optionnel.</span> Nous
                            respecterons au mieux
                            tes vœux
                            en fonction des vœux des autres apprenants. Utilise l'oeil masqué
                            <span class="!inline">
                                <EyeSlashIcon class="w-5 h-5 !inline" />
                            </span> pour mettre de côté
                            les
                            structures que tu ne souhaites pas enregistrer.
                        </p>
                    </div>
                    <div class="sm:flex lg:flex-col lg:ml-auto  pb-5 mt-5 lg:mt-0 mb-5 lg:pl-5">
                        <button type="button" @click="displaySidebar = true"
                            class="mr-3 mb-3 w-64 inline-flex items-center gap-x-2 rounded-md bg-cyan-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <span class="flex mx-auto items-center">
                                <StarIcon class="-ml-0.5 h-5 w-5 mr-3" aria-hidden="true" />
                                Voir
                                ma précédente sélection
                            </span>
                        </button><router-link to="/structures">
                            <button type="button"
                                class="w-64 ml-auto inline-flex items-center gap-x-2 rounded-md bg-cyan-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                <span class="flex mx-auto items-center">
                                    <BuildingOffice2Icon class="-ml-0.5 h-5 w-5 mr-3" aria-hidden="true" />
                                    Voir
                                    le
                                    catalogue
                                    de
                                    structures
                                </span>
                            </button></router-link>
                    </div>
                </div>
                <WishlistPreEventSideBar v-if="displaySidebar" @close="displaySidebar = false"
                    :structures="preEventWhishlists" />
                <div v-if="sortedStructures.length">
                    <h2 class="mt-10 mb-10  text-2xl lg:text-3xl text-white">Voici la liste des structures que tu
                        as mis en favori, enregistre tes vœux pour qu'ils soient validés</h2>
                    <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div v-for="(selectedStructure, index) in sortedStructures" :key="selectedStructure.id">
                            <StructureCardWishlist :displayedStructure="selectedStructure" :indexItem="index"
                                v-model="structureArrayToSelect" @update="changeModel"
                                @displayStructure="displayStructure(selectedStructure)" />
                        </div>
                    </div>
                    <div class="w-full flex mt-5 justify-center">
                        <button type="button" @click="checkWishlistsTotal"
                            :class="editableWishlist ? 'bg-opacity-50' : ''"
                            class="w-64 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <span class="flex mx-auto items-center">
                                <CheckCircleIcon class="-ml-0.5 h-5 w-5 mr-3" aria-hidden="true" />
                                Enregistre tes
                                vœux
                            </span>
                        </button> <button class="btn-read ml-2 " @click.prevent="store.clearStructures">Efface la
                            sélection</button>
                    </div>
                </div>
                <div
                    class="overflow-hidden bg-gray-50 sm:rounded-lg max-w-[1520px] mx-auto px-6 md:px-10 rounded-xl mt-10">
                    <div class="md:px-4 py-5 sm:p-6">
                        <h2 class="mt-10 md:mt-20 mb-10 text-2xl lg:text-3xl text-indigo-700">Édite les vœux que tu as
                            enregistré
                        </h2>
                        <p class="mb-5 text-lg">
                            Tu retrouves ici la liste des vœux que tu as enregistré. Tu peux encore
                            ici modifier tes vœux et justifier ton choix si tu le
                            souhaites.
                        </p>
                        <AlertMessage type="success" :title="'Bravo, tu as atteint le nombre de vœux maximum !'"
                            class="mb-10" v-if="editableWishlists" />
                        <div v-for="(selectedStructure, index) in eventWishlists" :key="selectedStructure.id">
                            <StructureCardWishlistDone :displayedStructure="selectedStructure" :indexItem="index"
                                :typeCard="'list'" @modify="updateWishlistUser" @delete="deleteWishlistUser" />
                        </div>
                        <div v-if="emptySpaces > 0">
                            <span v-for="( wishlistStructure, index ) in emptySpaces" :key="index">
                                <div class="mb-5 h-[120px] flex items-center justify-center relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center
                    hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <BuildingOffice2Icon class="w-12 h-12 stroke-1 stroke-gray-300 mr-5" />
                                    <span class="block text-md font-medium text-gray-900">Vœu pour mon alternance</span>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false" />
        <StructureAbstract :chosenStructure="selectedStructure" v-if="displayStructureAbstract"
            :show="displayStructureAbstract" @close="displayStructureAbstract = false" />
        <SuccessDialog v-if="displaySuccesDialog" title="Bravo, tu as enregistré tous tes vœux !"
            message="Tu pourras encore modifier jusqu'à 2 jours avant l'événement" secondaryButtonLabel="Fermer"
            @closeDialog="displaySuccesDialog = false" />
        <SelectionTrainingActionModal v-if="displayTrainingActionModal" @close="displayTrainingActionModal = false" />
    </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, inject, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import StructureCardWishlist from '@/components/structures/StructureCardWishlist.vue';
import WishlistServices from '@/services/WishlistServices.js';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import StructureCardWishlistDone from '@/components/structures/StructureCardWishlistDone.vue';
import fetchWishlistServices from '@/services/WishlistServices.js';
import client from '@/api/client.js';
import AlertMessage from '@/components/alert/AlertMessage.vue';
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import StructureAbstract from '@/components/structures/StructureAbstract.vue';
import SuccessDialog from '@/components/dialogs/SuccessDialog.vue';
import { BuildingOffice2Icon, StarIcon } from '@heroicons/vue/24/outline';
import WishlistPreEventSideBar from '@/components/whishlists/WishlistPreEventSideBar.vue';
import { EyeSlashIcon } from '@heroicons/vue/24/outline';
import SelectionTrainingActionModal from '@/components/training-actions/SelectionTrainingActionModal.vue';

const selectedStructure = ref(null);
const store = inject('store');
const route = useRoute();
const currentProfile = ref(store.chosenProfile);
const selectedStructures = computed(() => store.selectedStructures);
const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
let structureArrayToSelect = ref([]);
const currentEvent = ref(0);
const eventWishlists = ref([]);
let totalWishlists = ref(0);
const displayStructureAbstract = ref(false);
const displaySuccesDialog = ref(false);
const preEventWhishlists = ref([]);
const displaySidebar = ref(false);
const displayTrainingActionModal = ref(false);

let emptySpaces = ref(0);
const calculateEmptySpaces = () => {
    if (eventWishlists.value) {
        emptySpaces.value = 5 - eventWishlists.value.length;
    } else {
        emptySpaces.value = 5;
    }
};
calculateEmptySpaces();

const confirmTexts = [
    {
        title: 'Attention tu as déjà enregistré des vœux',
        message: 'Veux-tu écraser tes anciens vœux par ces nouveaux ?',
        primaryActionLabel: 'écraser mes vœux',
        secondaryActionLabel: 'annuler'
    },
]

const displayStructure = (structureToDisplay) => {
    displayStructureAbstract.value = true;
    selectedStructure.value = structureToDisplay;
};

const sortedStructures = computed(() => {
    // Sort the structures based on their initial choice and optional status
    return [...selectedStructures.value].sort((a, b) => {
        if (a.initial && !b.initial) return -1;
        if (!a.initial && b.initial) return 1;
        if (a.optional && !b.optional) return -1;
        if (!a.optional && b.optional) return 1;
        return 0;
    });
});

const editableWishlists = computed(() => {
    const initialChoices = eventWishlists.value?.filter(s => s.is_initial_choice).length;
    const optionalChoices = eventWishlists.value?.filter(s => s.is_optional_choice).length;

    if (initialChoices === 3 && optionalChoices == 2) {
        return true;
    }
});

const checkWishlistsTotal = () => {
    totalWishlists.value = eventWishlists.value.length;
    if (totalWishlists.value === 0) {
        // if there are no wishlists, post all the selected structures.
        postMultiple();
    } else if (totalWishlists.value === 5) {
        // if there are 5 wishlists, show a confirmation dialog to delete some.
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur : ';
        notificationTitle.value += 'Supprime d\'abord les vœux que tu ne souhaites plus garder';
    } else if (totalWishlists.value + structureArrayToSelect.value.length < 6) {
        // if the total number of wishlists and selected structure is less than 6, post all the selected structures.
        // if there are disabled structures, check if the total number of wishlists and selected structure is less than 6.
        const disabledStructures = structureArrayToSelect.value.filter(structure => structure.disabled);
        if (disabledStructures.length > 0) {
            // if there are disabled structures, check how much
            const totalWithoutDisabled = totalWishlists.value + structureArrayToSelect.value.length - disabledStructures.length;
            if (totalWithoutDisabled < 6) {
                // if the total number of wishlists and selected structure is less than 6, post all the selected structures.
                postMultiple();
            } else {
                // if the total number of wishlists and selected structure without disabled is more than 6, show a notification.
                notificationType.value = 'error';
                showNotification.value = true;
                notificationTitle.value = 'Erreur : ';
                notificationTitle.value += 'Supprime d\'abord les vœux que tu ne souhaites plus garder';
            }
        } else {
            postMultiple();
        }

    } else if (totalWishlists.value + structureArrayToSelect.value.length > 5) {
        // if the total number of wishlists and selected structure is more than 5, show a notification.
        const disabledStructures = structureArrayToSelect.value.filter(structure => structure.disabled);
        if (disabledStructures.length > 0) {
            // if there are disabled structures, check how much
            const totalWithoutDisabled = totalWishlists.value + structureArrayToSelect.value.length - disabledStructures.length;
            if (totalWithoutDisabled < 6) {
                // if the total number of wishlists and selected structure is less than 6, post all the selected structures.
                postMultiple();

            } else {
                // esle show a notification.
                showNotification.value = true;
                notificationTitle.value = 'Erreur : ';
                notificationTitle.value += 'Supprime d\'abord les vœux que tu ne souhaites plus garder';
            }
        } else {
            postMultiple();
        }
    }
};
const postMultiple = async () => {
    const arrayToPost = ref([]);
    const wishlistData = ref();

    structureArrayToSelect.value.forEach((selectedStructure) => {
        if (!selectedStructure.disabled) {
            if (!selectedStructure.initial && !selectedStructure.optional) {
                selectedStructure.optional = true;
            }
            arrayToPost.value.push({
                structure_id: selectedStructure.id,
                is_initial_choice: selectedStructure.initial,
                is_optional_choice: selectedStructure.optional,
                justification: '',
            });
        }
    });
    if (totalWishlists.value > 0) {
        eventWishlists.value.forEach((wishlist) => {
            const wishlistData = {
                structure_id: wishlist.structure_id,
                is_initial_choice: wishlist.is_initial_choice,
                is_optional_choice: wishlist.is_optional_choice,
                justification: wishlist.justification,
            };
            arrayToPost.value.push(wishlistData);
        });
    }

    wishlistData.value = {
        profile_id: currentProfile.value.id,
        event_id: currentEvent.value,
        is_pre_event: false,
        wishlists: arrayToPost,
    };
    try {
        const response = await client.post('/api/multiple-wishlists', wishlistData.value);
        store.clearStructures();
        eventWishlists.value = await fetchWishlistServices.fetchWishlistsByUserAndEvent(currentProfile.value.id, currentEvent.value, 0);
        if (eventWishlists.value.length === 5) {
            displaySuccesDialog.value = true;
        }
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        emptySpaces.value = 0;
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur : ';
        if (error.response) {
            if (error.response.data.message) { notificationTitle.value += error.response.data.message; }
            if (error.response.data.error) {
                notificationTitle.value += error.response.data.error;
            }
        }
    }
};
const updateWishlistUser = async (wishlistId, initialValue, justificationValue) => {
    let initialChoice = null;
    let optionalChoice = null;
    if (initialValue) {
        initialChoice = true;
        optionalChoice = false;
    } else {
        initialChoice = false;
        optionalChoice = true;
    }
    try {
        const wishlistData = {
            is_initial_choice: initialChoice,
            is_optional_choice: optionalChoice,
            is_pre_event: false,
            justification: justificationValue,

        };
        const response = await client.put(`/api/wishlists/${wishlistId}`, wishlistData);
        eventWishlists.value = await fetchWishlistServices.fetchWishlistsByUserAndEvent(currentProfile.value.id, currentEvent.value, 0);
        console.log(response);
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur : ';
        if (error.response) {
            if (error.response.data.message) { notificationTitle.value += error.response.data.message; }
            if (error.response.data.error) {
                notificationTitle.value += error.response.data.error;
            }
        }
    };
};
const deleteWishlistUser = async (whislistId) => {
    try {
        const response = await fetchWishlistServices.deleteWishlist(whislistId);
        try {
            eventWishlists.value = await fetchWishlistServices.fetchWishlistsByUserAndEvent(currentProfile.value.id, currentEvent.value, 0);
        } catch (error) {
            console.error('Failed to fetch whishlists', error);
        }
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        emptySpaces.value = 5 - eventWishlists.value.length;
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    };
};

const changeModel = (type, value, index) => {
    structureArrayToSelect.value[index][type] = value;
    if (type === "initial" && value) {
        structureArrayToSelect.value[index].optional = false;
    } else if (type === "optional" && value) {
        if (structureArrayToSelect.value[index].initial) { structureArrayToSelect.value[index].initial = false; }
    }
};

onMounted(async () => {
    store.setStructures();

    if (route.params.eventId === 'undefined') {
        displayTrainingActionModal.value = true;
    } else {
        try {
            currentEvent.value = route.params.eventId;
            preEventWhishlists.value = eventWishlists.value = await WishlistServices.fetchWishlistsByUserAndEvent(currentProfile.value.id, currentEvent.value, 1);
            try {
                eventWishlists.value = await WishlistServices.fetchWishlistsByUserAndEvent(currentProfile.value.id, currentEvent.value, 0);
                emptySpaces.value = 5 - eventWishlists.value.length;
            } catch (error) {
                console.error('Failed to fetch whishlists', error);
            }
        } catch (error) {
            console.error('Failed to fetch the next event:', error);
        }

        structureArrayToSelect.value = store.selectedStructures.map(structure => {
            return {
                id: structure.id,
                name: structure.name,
                disabled: false,
                initial: false,
                optional: false,
            };
        });
        const eventId = route.params.eventId;
        const userId = route.params.userId;
    }
});
</script>