<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <div>
    <div class="bg-white shadow sm:rounded-lg mb-2">
      <div class=" px-4 py-5 sm:p-6">
        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900" v-if="trainingAction">{{
              trainingAction?.degree.name }} - <span class="font-medium text-indigo-700">{{ trainingAction?.degree.city
                }} - {{
                  trainingAction?.year }}</span>
              <span v-if="!trainingAction?.is_ongoing"
                class="bg-role1 mr-2 inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 ml-3">Formation
                terminée</span>
            </h3>
            <div class="mb-4">Mon parcours FEST</div>
          </div>
        </div>
        <div class="">
          <nav class="mx-auto" aria-label="Progress" v-if="studentSteps?.length > 0">
            <ol role="list" class="overflow-hidden rounded-md lg:flex lg:rounded-none">
              <li v-for="( step, stepIdx ) in studentSteps" :key="step.id" class="relative overflow-hidden lg:flex-1">
                <div class="relative z-2"
                  :class="[stepIdx === 0 ? 'rounded-t-md border-b-0' : '', stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '', 'overflow-hidden border lg:border-0']">
                  <router-link :to="'/etape/' + step.id" :href="step.href">
                    <button class="text-left"><span
                        :class="[stepIdx !== 0 ? 'lg:pl-3' : '', 'lg:pl-6 flex lg:flex-col px-3 py-5 text-sm font-medium ']">
                        <span class="flex-shrink-0" v-if="(new
                          Date(step.end_datetime) < new Date()) && step.end_datetime">
                          <span
                            class="relative z-10 flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600">
                            <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
                          </span>
                        </span>
                        <span class="flex-shrink-0" v-else>
                          <span
                            class="relative z-10 bg-white flex h-10 w-10 items-center justify-center rounded-full border-2">
                            <span class="text-gray-500">{{ step.order }}</span>
                          </span>
                        </span>
                        <span class="pl-4 lg:pl-0 mt-0.5 lg:mt-2 flex min-w-0 flex-col">
                          <span class="text-sm font-semibold  text-indigo-600">{{ step.name }}</span>
                          <span class="text-sm font-medium text-gray-500" v-if="step.start_datetime">{{
                            fetchEventServices.formatDateSimple(new
                              Date(step.start_datetime)) }}</span>

                        </span>
                      </span></button>
                  </router-link>
                  <template v-if="stepIdx !== 0">
                    <!-- Separator -->
                    <div class="absolute inset-0 left-0 top-0 hidden w-1 lg:block" aria-hidden="true">
                    </div>
                  </template>
                </div>
                <div class="absolute inset-0 lg:flex items-start z-0 hidden mt-16" aria-hidden="true">
                  <div class="h-0.5 w-full bg-indigo-600" style="margin-top: -1.5rem;" />
                </div>
              </li>
            </ol>
          </nav>
          <button v-else-if="studentSteps && studentSteps?.length === 0" @click.prevent="createSteps" type="button"
            :disabled="isSubmitted"
            class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24" stroke-width="1" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5" />
            </svg>

            <span class="mt-2 block text-sm font-semibold text-gray-900">Créer mes étapes de FEST (formation en
              situation de
              travail)</span>
          </button>
        </div>
      </div>
      <AlertMessage v-if="displayAlert && !startTrainingAction" :type="alertType" :title="alertMessage"
        :content="alertContent" class="mb-5" />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import { CheckIcon } from '@heroicons/vue/24/solid';
import AlertMessage from '@/components/alert/AlertMessage.vue';
import client from '@/api/client';
import fetchEventServices from '@/services/EventServices.js';
import { orderBy } from 'lodash';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const store = inject('store');
const alertMessage = ref('');
const alertType = ref('info');
const displayAlert = ref(false);
const alertContent = ref('');
const startTrainingAction = ref(false);
const currentTrainingActions = ref([]);
const trainingAction = ref(null);
const profileId = store.chosenProfile.id;
const lastAction = ref(null);
const studentSteps = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const isSubmitted = ref(false);

const steps = ref([
  {
    id: 1,
    name: 'Rendez-vous 1',
    step_type: 'rendezvous',
    order: 1,
  },
  {
    id: 2,
    name: 'Rendez-vous 2',
    step_type: 'rendezvous',
    order: 2,
  },
  {
    id: 3,
    name: "Situation de travail 1",
    step_type: "situation",
    order: 3,
  },
  {
    id: 4,
    name: "Situation de travail 2",
    step_type: "situation",
    order: 4,
  },
  {
    id: 5,
    name: "Situation de travail 3",
    step_type: "situation",
    order: 5,
  },
  {
    id: 6,
    name: "Situation de travail 4",
    step_type: "situation",
    order: 6,
  },
  {
    id: 7,
    name: "Bilan",
    step_type: "bilan",
    order: 7
  }
],);

const changePage = (page) => {
  fetchEventServices.fetchEvents(page, pageSize.value).then((data) => {
    relevantEvents.value = data.data;
    currentPage.value = data.current_page;
  });
};


const props = defineProps({
  profileStudentId: [Number, String],
});

const fetchCurrentTrainingActions = async () => {
  const response = await client.get(`/api/training-actions/profile/${profileId}`);
  currentTrainingActions.value = response.data;
  lastAction.value = currentTrainingActions.value[currentTrainingActions.value.length - 1];
  trainingAction.value = lastAction.value;
  getSteps();
};

const order = ['id'];

const getSteps = async () => {
  try {
    const response = await client.get(`/api/training-action/${trainingAction.value.id}/steps/${props.profileStudentId}`);
    studentSteps.value = orderBy(response.data, 'order', 'asc');
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'erreur dans le chargement des étapes';
  }
}

const createSteps = async () => {
  let stepTemplates = [];
  stepTemplates = steps.value;
  if (!isSubmitted.value) {
    isSubmitted.value = true;
    try {
      const response = await client.post(`/api/training-action/${trainingAction.value.id}/learner/${props.profileStudentId}/pre-create-steps`, {
        step_template: stepTemplates,
        include_dates: false,
        include_documents: true,
      });
      showNotification.value = true;
      setTimeout(() => { showNotification.value = false; }, 3000);
      notificationType.value = 'success';
      notificationTitle.value = response.data.message;
      getSteps();
    } catch (error) {
      showNotification.value = true;
      notificationType.value = 'error';
      notificationTitle.value = error.response.data.message;
    }
  }
}

onMounted(() => {
  fetchCurrentTrainingActions();
});

</script>
